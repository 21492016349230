import {
GET_BRANDS_REQUEST,
GET_BRANDS_SUCCESS,
GET_BRANDS_FAILURE,
} from './actionTypes';

import {
    //all Brands
    GetBrandsRequest,
    GetBrandsSuccess,
    GetBrandsSuccessPayload,
    GetBrandsFailure,
    GetBrandsFailurePayload,
} from './types';


// all projects
export const getBrandsRequest = (): GetBrandsRequest => ({
    type: GET_BRANDS_REQUEST,
});

export const getBrandsSuccess = (payload: GetBrandsSuccessPayload): GetBrandsSuccess => ({
    type: GET_BRANDS_SUCCESS,
    payload,
});

export const getBrandsFailure = (payload: GetBrandsFailurePayload): GetBrandsFailure => ({
    type: GET_BRANDS_FAILURE,
    payload,
});