import { all, call, put, takeLatest } from "redux-saga/effects";
import { supabase } from "app/supabase-client";
import { PostgrestError } from "@supabase/supabase-js";
import { getProjectsSuccess, getProjectsFailure, getSupportedProjectsSuccess, getSupportedProjectsFailure } from "./actions";
import { GET_PROJECTS_REQUEST, GET_SUPPORTED_PROJECTS_REQUEST } from "./actionTypes";
import { GetProjectsSuccessPayload, GetSupportedProjectsSuccessPayload } from "./types";

// all projects
const getProjects = async (): Promise<GetProjectsSuccessPayload> => {
  const request = await supabase.rpc("get_project_list");

  if (request.error) {
    console.debug("getProjects", request);
    throw request.error;
  }

  return { projects: request.data };
};

function* getProjectsSaga() {
  try {
    const projects: GetProjectsSuccessPayload = yield call(() => getProjects());
    console.debug("getProjectsSaga", projects);
    yield put(getProjectsSuccess(projects));
  } catch (error) {
    yield put(getProjectsFailure({ error: (error as PostgrestError).message }));
  }
}

// supported projects
const getSupportedProjects = async (): Promise<GetSupportedProjectsSuccessPayload> => {
    const request = await supabase.rpc("get_supported_projects");
  
    if (request.error) {
      console.debug("getSupportedProjects", request);
      throw request.error;
    }
  
    return { supportedProjects: request.data };
  };
  
  function* getSupportedProjectsSaga() {
    try {
      const supportedProjects: GetSupportedProjectsSuccessPayload = yield call(() => getSupportedProjects());
      console.debug("getSupportedProjectsSaga", supportedProjects);
      yield put(getSupportedProjectsSuccess(supportedProjects));
    } catch (error) {
      yield put(getSupportedProjectsFailure({ error: (error as PostgrestError).message }));
    }
  }

//this is basically the watcher for every project requests
function* projectSaga() {
  yield all([takeLatest(GET_PROJECTS_REQUEST, getProjectsSaga)]);
  yield all([takeLatest(GET_SUPPORTED_PROJECTS_REQUEST, getSupportedProjectsSaga)]);
}

export default projectSaga;
