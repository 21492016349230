// DUCKS pattern

// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { Session } from '@supabase/supabase-js';

// const initialState = { session: null as Session | null, passwordRecovery: false };

// export const sessionSlice = createSlice({
//     name: 'session',
//     initialState,
//     reducers: {
//         sessionUpdated: (state, action: PayloadAction<Session>) => {
//             state.session = action.payload;
//         },
//         passwordRecoveryUpdated: (state, action: PayloadAction<boolean>) => {
//             state.passwordRecovery = action.payload;
//         }
//     }
// });

// export const { sessionUpdated, passwordRecoveryUpdated } = sessionSlice.actions;

// export default sessionSlice.reducer;

// DUCKS pattern

import { SessionActions, SessionState } from "./types";

const initialState: SessionState = {
  pending: false,
  error: "",
  success: "",
  session: null,
  passwordRecovery: false,
};

const reducers = (state = initialState, action: SessionActions) => {
  switch (action.type) {
    case "GET_SESSION_REQUEST":
      return {
        ...state,
        pending: true,
        error: "",
        success: "",
      };
    case "GET_SESSION_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        success: "",
      };

    case "GET_SESSION_SUCCESS":
      return {
        ...state,
        pending: false,
        error: "",
        session: action.payload,
      };

    case "SET_SESSION_REQUEST":
      return {
        ...state,
        pending: true,
      };

    case "SET_SESSION_SUCCESS":
      return {
        ...state,
        pending: false,
        session: action.payload,
      };

    case "SET_SESSION_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case "RESET_SESSION_REQUEST":
      return {
        ...state,
        pending: true,
        error: "",
        success: "",
      };
    case "RESET_SESSION_SUCCESS":
      return {
        ...state,
        pending: false,
        error: "",
        success: "User has logged out.",
        session: null,
      };
    case "RESET_SESSION_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case "PASSWORD_RECOVERY_REQUEST":
      return {
        ...state,
        pending: true,
        error: "",
        success: "",
      };
    case "PASSWORD_RECOVERY_SUCCESS":
      return {
        ...state,
        pending: false,
        error: "",
        passwordRecovery: true,
      };
    case "PASSWORD_RECOVERY_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        passwordRecovery: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducers;
