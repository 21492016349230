import { Session } from "@supabase/gotrue-js";
import {
  GET_SESSION_REQUEST,
  GET_SESSION_SUCCESS,
  GET_SESSION_FAILURE,
  RESET_SESSION_REQUEST,
  RESET_SESSION_SUCCESS,
  RESET_SESSION_FAILURE,
  SET_SESSION_REQUEST,
  SET_SESSION_SUCCESS,
  SET_SESSION_FAILURE,
  PASSWORD_RECOVERY_REQUEST,
  PASSWORD_RECOVERY_FAILURE,
  PASSWORD_RECOVERY_SUCCESS,
} from "./actionTypes";

import {
  GetSessionRequest,
  GetSessionSuccess,
  GetSessionSuccessPayload,
  GetSessionFailure,
  SessionFailurePayload,
  SetSessionRequest,
  SetSessionSuccess,
  SetSessionFailure,
  PasswordRecoveryFailurePayload,
} from "./types";

export const getSessionRequest = (): GetSessionRequest => ({
  type: GET_SESSION_REQUEST,
});

export const getSessionSuccess = (
  payload: GetSessionSuccessPayload
): GetSessionSuccess => ({
  type: GET_SESSION_SUCCESS,
  payload,
});

export const getSessionFailure = (
  payload: SessionFailurePayload
): GetSessionFailure => ({
  type: GET_SESSION_FAILURE,
  payload,
});

export const setSessionRequest = (payload: Session): SetSessionRequest => ({
    type: SET_SESSION_REQUEST,
    payload,
});

export const setSessionSuccess = (payload: Session): SetSessionSuccess => ({
    type: SET_SESSION_SUCCESS,
    payload,
});

export const setSessionFailure = (payload: SessionFailurePayload): SetSessionFailure => ({
    type: SET_SESSION_FAILURE,
    payload,
});
export const resetSessionRequest = () => ({
  type: RESET_SESSION_REQUEST,
});

export const resetSessionSuccess = () => ({
  type: RESET_SESSION_SUCCESS,
});

export const resetSessionFailure = (payload: SessionFailurePayload) => ({
  type: RESET_SESSION_FAILURE,
  payload,
});

export const passwordRecoveryRequest = () => ({
  type: PASSWORD_RECOVERY_REQUEST,
});

export const passwordRecoverySuccess = () => ({
  type: PASSWORD_RECOVERY_SUCCESS,
});

export const passwordRecoveryFailure = (payload: PasswordRecoveryFailurePayload) => ({
  type: PASSWORD_RECOVERY_FAILURE,
  payload,
});