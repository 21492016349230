import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm, FieldValues } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { addVehicleRequest } from "features/vehicles/actions";
import { getBrandsRequest } from "features/brands/actions";
import { getModelsRequest } from "features/models/actions";
import { IBrands } from "features/brands/types";
import { IModels } from "features/models/types";
import Select from "react-select";
import { BlueButton, StyledFormControl, StyledModal } from "app/theme";

function AddVehicleModal() {
  const dispatch = useAppDispatch();
  const { register, handleSubmit, setValue } = useForm();

  const [show, setShow] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);

  const brands = useAppSelector((state) => state.brands.brands) as IBrands[];
  const models = useAppSelector((state) => state.models.models) as IModels[];
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addVehicleToDatabase = (formData: FieldValues) => {
    // Call the Supabase function to add the vehicle
    console.debug("formData", formData);
    dispatch(
      addVehicleRequest({
        vehicle: {
          vin: formData.vin,
          number_plate: formData.number_plate,
          model_id: formData.model,
        },
      })
    );
    handleClose();
  };
  console.log("brands", brands);
  const brandsOptions = brands.map((brand) => ({
    value: brand.brand_id,
    label: brand.brand_name,
  }));

  const handleBrandChange = (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    setValue("brand", selectedOption.value); // update form value with selected option
    setSelectedBrand(selectedOption);
    setValue("model", null); // clear the model value when the maker is changed
    //dispatch a model request with the id of the selectedOption
    dispatch(getModelsRequest({ id: selectedOption.value }));
  };

  const handleModelChange = (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    setValue("model", selectedOption.value); // update form value with selected option
    setSelectedModel(selectedOption);
  };
  // Filter models for selected maker and create options array
  const modelOptions = models
    .filter((model) => model.brand_id === (selectedBrand as any).brand_id)
    .map((model) => ({
      value: model.model_id,
      label: model.model_name,
    }));

  const getBrands = async () => {
    dispatch(getBrandsRequest());
  };

  useEffect(() => {
    getBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BlueButton onClick={handleShow}>Add a Vehicle</BlueButton>

      <StyledModal show={show} onHide={handleClose} centered>
        <StyledModal.Header closeButton>
          <StyledModal.Title>Add Vehicle</StyledModal.Title>
        </StyledModal.Header>
        <StyledModal.Body>
          <Form
            onSubmit={handleSubmit((data) => {
              addVehicleToDatabase(data);
            })}
          >
            <Form.Group controlId="vin">
              <Form.Label>VIN</Form.Label>
              <StyledFormControl
                type="text"
                {...register("vin", { required: true })}
              />
            </Form.Group>
            <Form.Group controlId="numberPlate">
              <Form.Label>Number Plate</Form.Label>
              <StyledFormControl
                type="text"
                {...register("number_plate", { required: true })}
              />
            </Form.Group>
            {
              <Form.Group controlId="brand">
                <Form.Label>Brands</Form.Label>
                <Select
                  options={brandsOptions}
                  value={selectedBrand}
                  {...register("brand", {})}
                  onChange={handleBrandChange}
                />
              </Form.Group>
            }
            {
              <Form.Group controlId="model">
                <Form.Label>Models</Form.Label>
                {selectedBrand && (
                  <Select
                    options={modelOptions}
                    value={selectedModel}
                    placeholder="Select a model"
                    {...register("model", {})}
                    onChange={handleModelChange}
                  />
                )}
              </Form.Group>
            }
            <BlueButton type="submit" className={"mt-3"}>Add</BlueButton>
          </Form>
        </StyledModal.Body>
      </StyledModal>
    </>
  );
}

export default AddVehicleModal;
