import { useAppSelector } from "app/hooks";

import ImageLoop from "components/ImageLoop";

function Home() {
  const userName = useAppSelector((state) => state.user.user.username);
  return (
    <>
      <ImageLoop
        urls = {[
          "/images/forestGodRays.jpg",
          "/images/forestPhoto.jpg",
          "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg",
          "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png",
        ]}
        duration={20} 
        img_attributes={{style: {filter: "brightness(50%)"}}} 
      />
      <div
        className="d-flex align-items-center justify-content-center"
        style={{minHeight: "100vh", position: "relative" }}
      >
        <div className="text-center">
          <h1 className="display-1 fw-bold text-light">
            Welcome back {userName}!
          </h1>
          <p className="fs-3 text-light"> There is nothing here yet.</p>
          <p className="lead text-light">Admire the beautiful backgrounds</p>
        </div>
      </div>
    </>
  );
}

export default Home;
