import {
  GET_SALE_PRICES_FAILURE,
  GET_SALE_PRICES_REQUEST,
  GET_SALE_PRICES_SUCCESS,
} from "./actionTypes";
import {
  GetSalePricesFailure,
  GetSalePricesFailurePayload,
  GetSalePricesRequest,
  GetSalePricesSuccess,
  ISalePrice,
} from "./types";

// GET ACTIONS
export const getSalePricesRequest = (): GetSalePricesRequest => ({
  type: GET_SALE_PRICES_REQUEST,
});

export const getSalePricesSuccess = (
  payload: ISalePrice[]
): GetSalePricesSuccess => ({
  type: GET_SALE_PRICES_SUCCESS,
  payload,
});

export const getSalePricesFailure = (
  payload: GetSalePricesFailurePayload
): GetSalePricesFailure => ({
  type: GET_SALE_PRICES_FAILURE,
  payload,
});
