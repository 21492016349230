import { LightModeActions, LightModeState } from "./types";

const initialState: LightModeState = {
  pending: false,
  error: "",
  lightMode: false,
  success: "",
};

const reducers = (state = initialState, action: LightModeActions) => {
  switch (action.type) {
    //FOR ALL MODELS
    case "GET_LIGHT_MODE_REQUEST":
      return {
        ...state,
        pending: true,
        success: "",
      };
    case "GET_LIGHT_MODE_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        success: "",
      };

    case "GET_LIGHT_MODE_SUCCESS":
      return {
        ...state,
        pending: false,
        error: null,
        lightMode: action.payload,
      };

    case "SET_LIGHT_MODE_REQUEST":
      return {
        ...state,
        pending: true,
        success: "",
      };
    case "SET_LIGHT_MODE_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        success: "",
      };

    case "SET_LIGHT_MODE_SUCCESS":
      return {
        ...state,
        pending: false,
        error: null,
        lightMode: action.payload,
        success: "Light mode set successfully",
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducers;
