import { StyledCard } from "app/theme";
import { ICartItem } from "features/cart/types";
import { ISalePrice } from "features/sale_prices/types";

function CartItem(props: {
  item: ICartItem;
  salePrices: ISalePrice[];
  onRemove: () => void;
  index: number;
}) {
  return (
    <>
      <StyledCard style={{ width: "100%" }}>
        <StyledCard.Body>
          <StyledCard.Title>Carbon Transaction</StyledCard.Title>
          <StyledCard.Subtitle className="mb-2 text-muted">
            {props.item.vehicle}
          </StyledCard.Subtitle>
          <StyledCard.Text>
            <strong>Project:</strong> {props.item.project_name} <br />
            <strong>Amount :</strong> {props.item.credits} credits
          </StyledCard.Text>
          <StyledCard.Link
            href="#"
            className="text-danger"
            onClick={props.onRemove}
          >
            Remove
          </StyledCard.Link>
        </StyledCard.Body>
      </StyledCard>
    </>
  );
}

export default CartItem;
