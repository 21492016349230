// DUCKS pattern

import { UserActions, UserState } from "./types";

const initialState: UserState = {
  pending: false,
  error: "",
  success: "",
  user: {
    id: "",
    username: "",
    role: "client",
  },
};

const reducers = (state = initialState, action: UserActions) => {
  switch (action.type) {
    case "GET_USER_REQUEST":
      return {
        ...state,
        pending: true,
        error: "",
      };
    case "GET_USER_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case "GET_USER_SUCCESS":
      return {
        ...state,
        pending: false,
        error: "",
        user: action.payload.user,
      };

    case "RESET_USER_REQUEST":
      return {
        ...state,
        pending: true,
        error: "",
      };
    case "RESET_USER_SUCCESS":
      return {
        ...state,
        pending: false,
        error: "",
        success: "User has logged out.",
        user: {
          id: "",
          username: "",
          role: "client",
        },
      };
    case "RESET_USER_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducers;
