import styled from 'styled-components';
import { keyframes } from 'styled-components';

interface ImageLoopProps {
  urls: string[];
  duration: number;
  div_attributes?: React.HTMLAttributes<HTMLDivElement>;
  img_attributes?: React.HTMLAttributes<HTMLImageElement>;
}

const ImageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const fade = (index: number, total: number) => keyframes`
  // Handles first image (must be displayed at the begining of the animation)
  0% {
    opacity: ${index === 0 ? 1 : 0};
  }

  // Image appears
  ${Math.ceil((index - 1/3)/total * 100)}% {
    opacity: 0;
  }
  ${Math.ceil(index/total * 100)}% {
    opacity: 1;
  }

  // Image disappears
  ${Math.ceil((index + 2/3)/total * 100)}% {
    opacity: 1;
  }
  ${Math.ceil((index + 1)/total * 100)}% {
    opacity: 0;
  }

  // Handles last image
  ${Math.ceil((total - 1/3)/total * 100)}% {
    opacity: ${index === (total - 1) ? 1 : 0};
  }

  // Handles first image (must be displayed at the end of the animation)
  100% {
    opacity: ${index === 0 ? 1 : 0};
  }
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  object-fit: cover;
  width: 100vw;
  height: 100vh;

  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  // sets the duration of the animation
  animation-duration: ${(props: {duration: number, index: number, total:number}) => props.duration}s;

  // calls the fade function with the index and total number of images
  animation-name: ${(props: {duration: number, index: number, total:number}) => fade(props.index, props.total )};
`


//example of creating a mui dialog modal for creating new rows
const ImageLoop = (props: ImageLoopProps) => {

  const { urls, duration, div_attributes, img_attributes } = {...props };

  return (

    <ImageContainer
      {...div_attributes}
    >
      {
        urls.map((url, index) => {
          return <Image 
            key={index}
            index={index}
            total={urls.length}
            src={url}
            duration={duration}
            alt={`Loop ${index}`} 
            {...img_attributes}
            // style={style}
          />
          }
        )
      }
    </ImageContainer>
  );
};

export default ImageLoop;
