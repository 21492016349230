import { useAppSelector } from "../../app/hooks";
import AuthForm from "./AuthForm";
import { Navigate } from "react-router-dom";
import { StyledContainer } from "app/theme";

export default function Auth() {
  const session = useAppSelector((state) => state.session.session);
  return (
    <>
    <StyledContainer className="d-flex align-items-center justify-content-center" style={{
        minHeight: "85vh",
    }} fluid>
        {session ? <Navigate to="/" /> : null}
        <AuthForm></AuthForm>
    </StyledContainer>
    </>
  );
}
