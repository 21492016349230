import { all, call, put, takeLatest } from "redux-saga/effects";
import { supabase } from "app/supabase-client";
import {
  getVehiclesSuccess,
  getVehiclesFailure,
  getVehicleSuccess,
  getVehicleFailure,
  deleteVehicleFailure,
  deleteVehicleSuccess,
  addVehicleFailure,
  addVehicleSuccess,
} from "./actions";

import {
  GET_VEHICLES_REQUEST,
  GET_VEHICLE_REQUEST,
  DELETE_VEHICLE_REQUEST,
  ADD_VEHICLE_REQUEST,
} from "./actionTypes";

import {
  DeleteVehicleRequest,
  GetVehicleRequest,
  GetVehiclesSuccessPayload,
  GetVehicleSuccessPayload,
  AddVehicleRequest,
  IVehicle,
} from "./types";
import { PostgrestError } from "@supabase/supabase-js";

// Actions
// GET_VEHICLES_REQUEST
// GET_VEHICLE_REQUEST

const getVehicles = async (): Promise<GetVehiclesSuccessPayload> => {
  const request = await supabase.rpc("get_user_vehicles");

  if (request.error) {
    console.debug("getVehicles", request);
    throw request.error;
  }

  return { vehicles: request.data };
};

function* getVehiclesSaga() {
  try {
    const vehicles: GetVehiclesSuccessPayload = yield call(() => getVehicles());
    console.debug("getVehiclesSaga", vehicles);
    yield put(getVehiclesSuccess(vehicles));
  } catch (error) {
    yield put(getVehiclesFailure({ error: (error as PostgrestError).message }));
  }
}

const getVehicle = async (id: string): Promise<GetVehicleSuccessPayload> => {
  const request = await supabase.rpc("get_vehicle_details", {
    _vehicle_id: id,
  });

  if (request.error) {
    console.debug("getVehicle", request);
    throw request.error;
  }

  return { vehicle: request.data[0] };
};

function* getVehicleSaga(action: GetVehicleRequest) {
  try {
    const vehicle: GetVehicleSuccessPayload = yield call(() =>
      getVehicle(action.payload.id)
    );
    console.debug("getVehicleSaga", vehicle);
    yield put(getVehicleSuccess(vehicle));
  } catch (error) {
    yield put(getVehicleFailure({ error: (error as PostgrestError).message }));
  }
}

//create a saga to add a vehicle
const addVehicles = async (vehicle : IVehicle) => {
  const request = await supabase.rpc("add_vehicle", { _vin: vehicle.vin, _number_plate: vehicle.number_plate, _model_id: vehicle.model_id });
  if (request.error) {
    throw request.error;
  }
};

function* addVehicleSaga(action: AddVehicleRequest) {
  try {
    yield call(() => addVehicles(action.payload.vehicle));
    console.debug("addVehicleSaga")
    yield put(addVehicleSuccess());
    yield call(() => getVehiclesSaga());
  } catch (error) {
    yield put(addVehicleFailure({ error: (error as PostgrestError).message }));
  }
}

const deleteVehicles = async (id: string) => {
  const request = await supabase.rpc("delete_vehicle", { _vehicle_id: id });
  if (request.error) {
    throw request.error;
  }
  return id;
};

function* deleteVehicleSaga(action: DeleteVehicleRequest) {
  try {
    const vehicle_id: string = yield call(() =>
      deleteVehicles(action.payload.id)
    );
    yield put(deleteVehicleSuccess(vehicle_id));
  } catch (error) {
    yield put(
      deleteVehicleFailure({ error: (error as PostgrestError).message })
    );
  }
}

//this is basically the watcher for every vehicle requests
function* vehiclesSaga() {
  yield all([takeLatest(GET_VEHICLES_REQUEST, getVehiclesSaga)]);
  yield all([takeLatest(GET_VEHICLE_REQUEST, getVehicleSaga)]);
  yield all([takeLatest(DELETE_VEHICLE_REQUEST, deleteVehicleSaga)]);
  yield all([takeLatest(ADD_VEHICLE_REQUEST, addVehicleSaga)]);
}

export default vehiclesSaga;
