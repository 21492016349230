import { StyledContainer } from "app/theme";
import { PasswordRecovery } from "./ResetPasswordForm";

export function PasswordRecoveryPage() {
  return (
    <>
      <StyledContainer className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "95vh" }}
        fluid>
        <PasswordRecovery></PasswordRecovery>
      </StyledContainer>
    </>
  );
}
