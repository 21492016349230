import { useAppSelector } from "app/hooks";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const StyledAlert = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.green};
  z-index: 999;
  color: ${(props) => props.theme.colors.base};
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slide-in 0.3s ease-in-out;

  @keyframes slide-in {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: ${(props) => props.theme.colors.base};
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
`;

function SuccessAlert() {
  const [display, setDisplay] = useState(false);

  const cartSuccess = useAppSelector((state) => state.cart.success);
  const vehiclesSuccess = useAppSelector((state) => state.vehicles.success);
  const sessionSuccess = useAppSelector((state) => state.session.success);

  const successes = useMemo(() => {
    return [cartSuccess, vehiclesSuccess, sessionSuccess];
  }, [cartSuccess, vehiclesSuccess, sessionSuccess]);

  useEffect(() => {
    // Loop through all errors, if any of them are true, set display to true for 2 seconds
    for (let i = 0; i < successes.length; i++) {
      if (successes[i]) {
        setDisplay(true);
        setTimeout(() => {
          setDisplay(false);
        }, 3000);
        break;
      }
    }
  }, [successes]);

  return (
    <>
      {display ? (
        <StyledAlert>
          {successes.map((success: any, index: number) => {
            return success ? (
              <b key={`error-${index}`}>
                {success}
                <br></br>
              </b>
            ) : null;
          })}
          <CloseButton onClick={() => setDisplay(false)}>✗</CloseButton>
        </StyledAlert>
      ) : null}
    </>
  );
}

export default SuccessAlert;
