export const cartJsonSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "cartJsonSchema",
  type: "object",
  properties: {
    items: {
      type: "array",
      items: {
        type: "object",
        properties: {
          vehicle: {
            type: "string",
          },
          vehicle_id: {
            type: "string",
          },
          project_name: {
            type: "string",
          },
          project: {
            type: "string",
          },
          credits: {
            type: "string",
          },
        },
        required: [
          "vehicle",
          "vehicle_id",
          "project_name",
          "project",
          "credits",
        ],
      },
    },
  },
  required: ["items"],
};

export const cartItemJsonSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "cartItemJsonSchema",
  type: "object",
  properties: {
    vehicle: {
      type: "string",
    },
    vehicle_id: {
      type: "string",
    },
    project_name: {
      type: "string",
    },
    project: {
      type: "string",
    },
    credits: {
      type: "string",
    },
  },
  required: [
    "vehicle",
    "vehicle_id",
    "project_name",
    "project",
    "credits",
  ],
};
