export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILURE = "GET_CART_FAILURE";

export const ADD_CART_ITEM_REQUEST = "ADD_CART_ITEM_REQUEST";
export const ADD_CART_ITEM_SUCCESS = "ADD_CART_ITEM_SUCCESS";
export const ADD_CART_ITEM_FAILURE = "ADD_CART_ITEM_FAILURE";

export const DELETE_CART_REQUEST = "DELETE_CART_REQUEST";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";
export const DELETE_CART_FAILURE = "DELETE_CART_FAILURE";

export const REMOVE_CART_ITEM_REQUEST = "REMOVE_CART_ITEM_REQUEST";
export const REMOVE_CART_ITEM_SUCCESS = "REMOVE_CART_ITEM_SUCCESS";
export const REMOVE_CART_ITEM_FAILURE = "REMOVE_CART_ITEM_FAILURE";

export const CHECKOUT_CART_ITEM_REQUEST = "CHECKOUT_CART_ITEM_REQUEST";
export const CHECKOUT_CART_ITEM_SUCCESS = "CHECKOUT_CART_ITEM_SUCCESS";
export const CHECKOUT_CART_ITEM_FAILURE = "CHECKOUT_CART_ITEM_FAILURE";

export const CHECKOUT_CART_REQUEST = "CHECKOUT_CART_REQUEST";
export const CHECKOUT_CART_SUCCESS = "CHECKOUT_CART_SUCCESS";
export const CHECKOUT_CART_FAILURE = "CHECKOUT_CART_FAILURE";

export const SET_CLIENT_SECRET_REQUEST = "SET_CLIENT_SECRET_REQUEST";
export const SET_CLIENT_SECRET_SUCCESS = "SET_CLIENT_SECRET_SUCCESS";
export const SET_CLIENT_SECRET_FAILURE = "SET_CLIENT_SECRET_FAILURE";
