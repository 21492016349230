import { Card } from "react-bootstrap";

function ProjectItem(props: {
  project_id: string;
  project_name: string;
  project_description: string;
  credits: number;
  background_image: string;
}) {
  // Function to Cut the name if it is too long
  const cutName = (name: string) => {
    if (name.length > 30) {
      return name.substring(0, 30) + "...";
    } else {
      return name;
    }
  };

  return (
    <>
      <Card
        className="bg-dark text-white project-list-card"
        key={`card-${props.project_id}`}
      >
        <Card.Img
          src={props.background_image}
          alt="Card image"
        />
        <Card.ImgOverlay>
          <Card.Title style={{ fontSize: "0.7rem" }}>
            {cutName(props.project_name)}
          </Card.Title>
          {/* <Card.Text>{props.project_description}</Card.Text> */}
          <Card.Text style={{ fontSize: "0.7rem" }}>
            <b>Tons available : {props.credits}</b>
          </Card.Text>
        </Card.ImgOverlay>
      </Card>
    </>
  );
}

export default ProjectItem;
