import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import CartItem from "./CartItem";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  deleteCartRequest,
  getCartRequest,
  removeCartItemRequest,
} from "features/cart/actions";
import { ICartItem } from "features/cart/types";
import { GreenButton, RedButton, StyledContainer } from "app/theme";
import { getSalePricesRequest } from "features/sale_prices/actions";
import { ISalePrice } from "features/sale_prices/types";
import ButtonCheckout from "./CheckoutModal/ButtonCheckout";
import { useNavigate } from "react-router-dom";
import { RouterPath } from "app/router-path";
import { BsFillCartCheckFill } from "react-icons/bs";

function Cart() {
  const dispatch = useAppDispatch();
  const cartItems = useAppSelector((state) => state.cart.items);
  const salePrices = useAppSelector((state) => state.sale_prices.salePrices);
  const navigate = useNavigate();

  const removeItem = (item: ICartItem) => {
    dispatch(removeCartItemRequest(item));
  };

  const removeAll = () => {
    dispatch(deleteCartRequest());
  };

  const checkoutAll = () => {
    navigate(RouterPath.checkout);
  };

  const checkoutSubscription = () => {
    navigate(RouterPath.checkoutSubscription);
  };

  useEffect(() => {
    dispatch(getCartRequest());
    dispatch(getSalePricesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledContainer fluid style={{ padding: "80px 20px 20px 20px" }}>
        <StyledContainer className="mb-3 d-flex mt-3" fluid>
          <RedButton onClick={removeAll} disabled={cartItems.length === 0}>
            Remove all
          </RedButton>
          &nbsp; &nbsp;
          <ButtonCheckout
            cartItems={cartItems as ICartItem[]}
            salePrices={salePrices as ISalePrice[]}
            checkout={checkoutAll}
          ></ButtonCheckout>
          &nbsp; &nbsp;
          <GreenButton
            onClick={
              checkoutSubscription
            }>
            Subscription Checkout
            <BsFillCartCheckFill />
          </GreenButton>
        </StyledContainer>
        <StyledContainer fluid>
          {cartItems.length === 0 ? (
            <h3 className="text-center">Cart is empty</h3>
          ) : null}
          <Row>
            {cartItems.map((item: ICartItem, index: number) => (
              <Col key={`cart-item-${index}`} className="mb-2" sm="6">
                <CartItem
                  item={item}
                  onRemove={() => removeItem(item)}
                  salePrices={salePrices as ISalePrice[]}
                  index={index}
                />
              </Col>
            ))}
          </Row>
        </StyledContainer>
      </StyledContainer>
    </>
  );
}

export default Cart;
