import { Session } from "@supabase/gotrue-js";
import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  RESET_USER_REQUEST,
  RESET_USER_SUCCESS,
  RESET_USER_FAILURE,
} from "./actionTypes";

import {
  GetUserRequest,
  GetUserSuccess,
  GetUserSuccessPayload,
  GetUserFailure,
  GetUserFailurePayload,
} from "./types";

export const getUserRequest = (payload: Session): GetUserRequest => ({
  type: GET_USER_REQUEST,
  payload,
});

export const getUserSuccess = (
  payload: GetUserSuccessPayload
): GetUserSuccess => ({
  type: GET_USER_SUCCESS,
  payload,
});

export const getUserFailure = (
  payload: GetUserFailurePayload
): GetUserFailure => ({
  type: GET_USER_FAILURE,
  payload,
});

export const resetUserRequest = () => ({
  type: RESET_USER_REQUEST,
});

export const resetUserSuccess = () => ({
  type: RESET_USER_SUCCESS,
});

export const resetUserFailure = (payload: GetUserFailurePayload) => ({
  type: RESET_USER_FAILURE,
  payload,
});
