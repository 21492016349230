import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getLightModeSuccess,
  getLightModeFailure,
  setLightModeSuccess,
  setLightModeFailure,
} from "./actions";
import { GET_LIGHT_MODE_REQUEST, SET_LIGHT_MODE_REQUEST } from "./actionTypes";

const getLightMode = async (): Promise<boolean> => {
  try {
    const lightMode = localStorage.getItem("lightMode");
    if (lightMode) {
      return lightMode === "true";
    } else {
      return false;
    }
  } catch (error) {
    throw new Error("Error getting lightMode");
  }
};

function* getLightModeSaga() {
  try {
    const lightMode: boolean = yield call(() => getLightMode());
    yield put(getLightModeSuccess(lightMode));
  } catch (error) {
    yield put(getLightModeFailure({ error: error as any }));
  }
}

// Set
const setLightMode = async (lightMode: boolean): Promise<boolean> => {
  try {
    localStorage.setItem("lightMode", String(lightMode));
    console.debug("setLightMode: ", lightMode)
    return lightMode;
  } catch (error) {
    throw new Error("Error setting lightMode");
  }
};

function* setLightModeSaga(action: any) {
  try {
    const lightMode: boolean = yield call(() => setLightMode(action.payload));
    yield put(setLightModeSuccess(lightMode));
  } catch (error) {
    yield put(setLightModeFailure({ error: error as any }));
  }
}

function* lightModeSaga() {
  yield all([takeLatest(GET_LIGHT_MODE_REQUEST, getLightModeSaga)]);
  yield all([takeLatest(SET_LIGHT_MODE_REQUEST, setLightModeSaga)]);
}

export default lightModeSaga;
