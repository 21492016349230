import {
  GET_LIGHT_MODE_FAILURE,
  GET_LIGHT_MODE_REQUEST,
  GET_LIGHT_MODE_SUCCESS,
  SET_LIGHT_MODE_FAILURE,
  SET_LIGHT_MODE_REQUEST,
  SET_LIGHT_MODE_SUCCESS,
} from "./actionTypes";
import {
  GetLightModeFailure,
  GetLightModeFailurePayload,
  GetLightModeRequest,
  GetLightModeSuccess,
  SetLightModeFailure,
  SetLightModeFailurePayload,
  SetLightModeRequest,
  SetLightModeSuccess,
} from "./types";

// GET ACTIONS
export const getLightModeRequest = (): GetLightModeRequest => ({
  type: GET_LIGHT_MODE_REQUEST,
});

export const getLightModeSuccess = (payload: boolean): GetLightModeSuccess => ({
  type: GET_LIGHT_MODE_SUCCESS,
  payload,
});

export const getLightModeFailure = (
  payload: GetLightModeFailurePayload
): GetLightModeFailure => ({
  type: GET_LIGHT_MODE_FAILURE,
  payload,
});

// SET ACTIONS
export const setLightModeRequest = (payload: boolean): SetLightModeRequest => ({
  type: SET_LIGHT_MODE_REQUEST,
  payload,
});

export const setLightModeSuccess = (payload: boolean): SetLightModeSuccess => ({
  type: SET_LIGHT_MODE_SUCCESS,
  payload,
});

export const setLightModeFailure = (
  payload: SetLightModeFailurePayload
): SetLightModeFailure => ({
  type: SET_LIGHT_MODE_FAILURE,
  payload,
});
