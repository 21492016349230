import {
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  GET_CART_FAILURE,
  DELETE_CART_FAILURE,
  DELETE_CART_REQUEST,
  DELETE_CART_SUCCESS,
  REMOVE_CART_ITEM_FAILURE,
  REMOVE_CART_ITEM_REQUEST,
  REMOVE_CART_ITEM_SUCCESS,
  ADD_CART_ITEM_REQUEST,
  ADD_CART_ITEM_SUCCESS,
  ADD_CART_ITEM_FAILURE,
  CHECKOUT_CART_ITEM_REQUEST,
  CHECKOUT_CART_ITEM_SUCCESS,
  CHECKOUT_CART_ITEM_FAILURE,
  CHECKOUT_CART_REQUEST,
  CHECKOUT_CART_SUCCESS,
  CHECKOUT_CART_FAILURE,
  SET_CLIENT_SECRET_REQUEST,
  SET_CLIENT_SECRET_SUCCESS,
  SET_CLIENT_SECRET_FAILURE,
} from "./actionTypes";

import {
  GetCartRequest,
  GetCartSuccess,
  GetCartFailure,
  GetCartSuccessPayload,
  GetCartFailurePayload,
  AddCartItemSuccess,
  AddCartItemSuccessPayload,
  AddCartItemRequest,
  AddCartItemFailure,
  AddCartItemFailurePayload,
  DeleteCartFailure,
  DeleteCartFailurePayload,
  DeleteCartRequest,
  DeleteCartSuccess,
  DeleteCartSuccessPayload,
  RemoveCartItemFailure,
  RemoveCartItemFailurePayload,
  RemoveCartItemRequest,
  RemoveCartItemSuccess,
  RemoveCartItemSuccessPayload,
  CheckoutCartItemRequest,
  CheckoutCartItemSuccess,
  CheckoutCartItemFailure,
  CheckoutCartItemFailurePayload,
  ICartItem,
  CheckoutCartRequest,
  CheckoutCartRequestPayload,
  CheckoutCartSuccess,
  CheckoutCartFailurePayload,
  CheckoutCartFailure,
  SetClientSecretRequest,
  SetClientSecretSuccess,
  SetClientSecretFailurePayload,
  SetClientSecretFailure,
} from "./types";

// GET ACTIONS
export const getCartRequest = (): GetCartRequest => ({
  type: GET_CART_REQUEST,
});

export const getCartSuccess = (
  payload: GetCartSuccessPayload
): GetCartSuccess => ({
  type: GET_CART_SUCCESS,
  payload,
});

export const getCartFailure = (
  payload: GetCartFailurePayload
): GetCartFailure => ({
  type: GET_CART_FAILURE,
  payload,
});

// ADD ACTIONS
export const addCartItemRequest = (
  payload: ICartItem
): AddCartItemRequest => ({
  type: ADD_CART_ITEM_REQUEST,
  payload,
});

export const addCartItemSuccess = (
  payload: AddCartItemSuccessPayload
): AddCartItemSuccess => ({
  type: ADD_CART_ITEM_SUCCESS,
  payload,
});

export const addCartItemFailure = (
  payload: AddCartItemFailurePayload
): AddCartItemFailure => ({
  type: ADD_CART_ITEM_FAILURE,
  payload,
});


// DELETE ACTIONS
export const deleteCartRequest = (): DeleteCartRequest => ({
  type: DELETE_CART_REQUEST,
});

export const deleteCartSuccess = (
  payload: DeleteCartSuccessPayload
): DeleteCartSuccess => ({
  type: DELETE_CART_SUCCESS,
  payload,
});

export const deleteCartFailure = (
  payload: DeleteCartFailurePayload
): DeleteCartFailure => ({
  type: DELETE_CART_FAILURE,
  payload,
});

// REMOVE ACTIONS
export const removeCartItemRequest = (
  payload: ICartItem
): RemoveCartItemRequest => ({
  type: REMOVE_CART_ITEM_REQUEST,
  payload,
});

export const removeCartItemSuccess = (
  payload: RemoveCartItemSuccessPayload
): RemoveCartItemSuccess => ({
  type: REMOVE_CART_ITEM_SUCCESS,
  payload,
});

export const removeCartItemFailure = (
  payload: RemoveCartItemFailurePayload
): RemoveCartItemFailure => ({
  type: REMOVE_CART_ITEM_FAILURE,
  payload,
});

// CHECKOUT CART ITEM ACTIONS

export const checkoutCartItemRequest = (
  payload: ICartItem
): CheckoutCartItemRequest => ({
  type: CHECKOUT_CART_ITEM_REQUEST,
  payload,
});

export const checkoutCartItemSuccess = (
  payload: ICartItem
): CheckoutCartItemSuccess => ({
  type: CHECKOUT_CART_ITEM_SUCCESS,
  payload,
});

export const checkoutCartItemFailure = (
  payload: CheckoutCartItemFailurePayload
): CheckoutCartItemFailure => ({
  type: CHECKOUT_CART_ITEM_FAILURE,
  payload,
});

// CHECKOUT CART ACTIONS
export const checkoutCartRequest = (
  payload: CheckoutCartRequestPayload,
) : CheckoutCartRequest => ({
  type: CHECKOUT_CART_REQUEST,
  payload,
});

export const checkoutCartSuccess = (
  payload: string
) : CheckoutCartSuccess => ({
  type: CHECKOUT_CART_SUCCESS,
  payload,
});

export const checkoutCartFailure = (
  payload: CheckoutCartFailurePayload,
) : CheckoutCartFailure => ({
  type: CHECKOUT_CART_FAILURE,
  payload,
});

// Set Client Secret
export const setClientSecretRequest = (
  payload: string
) : SetClientSecretRequest => ({
  type: SET_CLIENT_SECRET_REQUEST,
  payload,
});

export const setClientSecretSuccess = (
  payload: string
) : SetClientSecretSuccess => ({
  type: SET_CLIENT_SECRET_SUCCESS,
  payload,
});

export const setClientSecretFailure = (
  payload: SetClientSecretFailurePayload,
) : SetClientSecretFailure => ({
  type: SET_CLIENT_SECRET_FAILURE,
  payload,
});