import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useState } from "react";

interface CreateModalProps {
  columns: MRT_ColumnDef<any>[];
  onClose: () => void;
  onSubmit: (values: any) => void;
  open: boolean;
  model: string;
}

//example of creating a mui dialog modal for creating new rows
const CreateModal = ({
  open,
  columns,
  onClose,
  onSubmit,
  model,
}: CreateModalProps) => {
	// Example from Material React Table. See https://www.material-react-table.com/docs/examples/editing-crud
  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {} as any)
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New {model}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            {columns.map((column) => (
							// Key is required for React to keep track of the elements
							// Name is required for the form to be submitted
							// Label is the text that appears above the input
							// accessorKey and header are keys used by the columns of MRT_ColumnDef type (React Material Table)
              <TextField
                key={column.accessorKey as string}
                label={column.header}
                name={column.accessorKey as string}
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              />
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateModal;
