import { Tab, Tabs } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Admin.scss";
import DataTableEditor from "../../components/DataTableEditor/DataTableEditor";

function AdminDashboard() {
  // Here we should get the models from the store or from the backend
  const models = [
    "carbon_certificates",
    "credit_transactions",
    "brands",
    "profiles",
    "projects",
    "registries",
    "roles",
    "vehicles",
    "models"
  ];

  return (
    <>
      <Tabs defaultActiveKey={models[0]} id="fill-tab-example" fill>
        {models.map((model) => {
          return (
            <Tab eventKey={model} title={model} key={`tab-${model}`}>
              <DataTableEditor model={model}></DataTableEditor>
            </Tab>
          );
        })}
      </Tabs>
    </>
  );
}

export default AdminDashboard;
