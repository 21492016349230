import { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { PaymentIntent, StripePaymentElementOptions } from "@stripe/stripe-js";
import { GreenButton, StyledContainer } from "app/theme";
import CheckoutSuccess from "../CheckoutSuccess";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { deleteCartRequest } from "features/cart/actions";
import { Session } from "@supabase/gotrue-js";

export default function CheckoutForm(
  props: {
    return_url: string;
  }
) {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const user = useAppSelector((state) => state.user.user);
  const session = useAppSelector((state) => state.session.session) as Session;
  const clientSecret = useAppSelector((state) => state.cart.clientSecret);

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<
    "processing" | "failure" | "success"
  >("processing");
  const [paymentElementOptions, setPaymentElementOptions] =
    useState<StripePaymentElementOptions>({
      layout: "tabs",
    });

  useEffect(() => {
    if (
      !paymentElementOptions.defaultValues?.billingDetails?.name ||
      !paymentElementOptions.defaultValues?.billingDetails?.email
    ) {
      setPaymentElementOptions({
        layout: "tabs",
        defaultValues: {
          billingDetails: {
            name: user?.username,
            email: session?.user.email,
          },
        },
      });
      return;
    }

    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    // Get redirect_status from URL
    const redirectStatus = new URLSearchParams(window.location.search).get(
      "redirect_status"
    );

    switch(redirectStatus) {
      case "failed":
        setIsSuccess("failure");
        setMessage("Your payment failed.");
        break;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch ((paymentIntent as PaymentIntent).status) {
        case "succeeded":
          dispatch(deleteCartRequest());
          setIsSuccess("success");
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setIsSuccess("processing");
          setMessage("Your payment is processing.");
          break;
        case "requires_confirmation":
          setIsSuccess("failure");
          setMessage("Your payment requires confirmation to proceed.");
          break;
        case "requires_action":
          setIsSuccess("failure");
          setMessage("Your payment requires action to proceed.");
          break;
        case "canceled":
          setIsSuccess("failure");
          setMessage("Your payment was canceled.");
          break;
        default:
          setIsSuccess("failure");
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, user, paymentElementOptions, session]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: props.return_url,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message as string);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <>
      {isSuccess === "processing" || isSuccess === "failure" ? (
        <StyledContainer fluid style={{ padding: "80px 20px 20px 20px" }}>
          <form id="payment-form" onSubmit={handleSubmit}>
            <LinkAuthenticationElement id="link-authentication-element" />
            <PaymentElement
              id="payment-element"
              options={paymentElementOptions as StripePaymentElementOptions}
            />
            <GreenButton
              type="submit"
              disabled={isLoading || !stripe || !elements}
              id="submit"
              className="mt-3"
            >
              <span id="button-text">
                {isLoading ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "Pay now"
                )}
              </span>
            </GreenButton>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
          </form>
        </StyledContainer>
      ) : isSuccess === "success" ? (
        <CheckoutSuccess
          message={message}
          description="Thank you for your purchase!"
        />
      ) : null}
    </>
  );
}
