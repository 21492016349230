import { SetStateAction, useEffect, useState } from "react";
import {Button, Col, Container, Form, Row } from "react-bootstrap";
import { AiFillDollarCircle } from "react-icons/ai";
import { useForm, FieldValues } from "react-hook-form";
import "./BuyCreditModal.scss";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ICartItem } from "features/cart/types";
import { getProjectsRequest } from "features/projects/actions";
import { addCartItemRequest } from "features/cart/actions";
import { BlueButton, GreenButton, RedButton, StyledFormControl, StyledFormSelect, StyledModal } from "app/theme";
import ProjectItem from "./ProjectItem";

function BuyCreditModal(props: { vehicle: string; vehicleId: string }) {
  const dispatch = useAppDispatch();
  const { register, handleSubmit } = useForm();
  const projects = useAppSelector((state) => state.projects.projects);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedProject, setSelectedProject] = useState<string>();

  const getProjects = async () => {
    dispatch(getProjectsRequest());
  };

  const addToCart = (formData: FieldValues) => {
    if (formData.credits === 0) {
      alert("Please enter credits");
      return;
    }
    if (!selectedProject) {
      alert("Please select a project");
      return;
    }
    let project_name = projects.find(
      (project: { project_id: string | undefined }) =>
        project.project_id === selectedProject
    )?.project_name;
    let item = {
      vehicle: props.vehicle,
      vehicle_id: props.vehicleId,
      project_name: project_name,
    } as ICartItem;
    item = { ...item, ...formData };

    dispatch(addCartItemRequest(item));
    handleClose();
  };

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BlueButton onClick={handleShow}>
        <AiFillDollarCircle />
      </BlueButton>

      <StyledModal show={show} onHide={handleClose} centered size="lg">
        <StyledModal.Header closeButton>
          Buy Compensation in Tons of CO2 for {props.vehicle}
        </StyledModal.Header>
        <StyledModal.Body>
          <Form
            onSubmit={handleSubmit((data) => {
              addToCart(data);
            })}
          >
            <Container className="mb-3 project-list-container">
              <Row className="project-list-row">
                {projects.map((project: any) => (
                  <Col
                    className="project-list-col"
                    key={`col-item-${project.project_id}`}
                  >
                    <Button
                      className={`image-btn`}
                      // onClick={() => setSelectedProject(project.project_id)}
                    >
                      <ProjectItem {...project} />
                    </Button>
                  </Col>
                ))}
              </Row>
            </Container>
            <Form.Group className="mb-3" controlId="buyCredit.Project">
              <Form.Label>Project List</Form.Label>
              <StyledFormSelect
                aria-label="Default select example"
                {...register("project", { required: true })}
                onChange={(e: { target: { value: SetStateAction<string | undefined>; }; }) => setSelectedProject(e.target.value)}
              >
                <option
                    value=""
                    key={`options-default`}
                  >Choose a project</option>
                {projects.map((project: any) => {
                  return (
                  <option
                    value={project.project_id}
                    key={`options-${project.project_id}`}
                  >
                    {project.project_name}
                  </option>
                )})}
              </StyledFormSelect>
            </Form.Group>
            <Form.Group className="mb-3" controlId="buyCredit.credits">
              <Form.Label>Tons amount</Form.Label>
              <StyledFormControl
                type="number"
                {...register("credits", { required: true })}
                defaultValue={1}
                min={1}
              />
            </Form.Group>
            <RedButton onClick={handleClose}>
              Close
            </RedButton>
            &nbsp;&nbsp;
            <GreenButton type="submit" variant="success">
              Add to cart
            </GreenButton>
          </Form>
        </StyledModal.Body>
      </StyledModal>
    </>
  );
}

export default BuyCreditModal;
