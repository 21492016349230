import { SalePricesActions, SalePricesState } from "./types";

const initialState: SalePricesState = {
    pending: false,
    salePrices: [],
    error: "",
};

const reducers = (state = initialState, action: SalePricesActions) => {
    switch (action.type) {
        case "GET_SALE_PRICES_REQUEST":
            return {
                ...state,
                pending: true,
            };
        case "GET_SALE_PRICES_SUCCESS":
            return {
                ...state,
                pending: false,
                error: null,
                salePrices: action.payload,
            };
        case "GET_SALE_PRICES_FAILURE":
            return {
                ...state,
                pending: false,
                error: action.payload.error,
            };
        default:
            return {
                ...state,
            }
    }
}

export default reducers;