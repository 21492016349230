import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getSupportedProjectsRequest } from "features/projects/actions";
import { StyledAccordion, StyledContainer } from "app/theme";
import DashboardMenu from "components/DashboardMenu";

function SupportedProjectsList() {
  const userId = useAppSelector((state) => state.user.user.id);
  const dispatch = useAppDispatch();
  const supportedProjects = useAppSelector(
    (state) => state.projects.supportedProjects
  );

  const getSupportedProjects = async () => {
    dispatch(getSupportedProjectsRequest());
  };

  useEffect(() => {
    if (userId) getSupportedProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <StyledContainer fluid style={{ padding: "80px 20px 20px 20px" }}>
      <DashboardMenu></DashboardMenu>

      <StyledContainer fluid>
        <StyledAccordion className="mt-4">
          <h2>Supported Projects</h2>
          {supportedProjects?.map((project: any, index: number) => {
            return (
              <StyledAccordion.Item
                eventKey={index.toString()}
                key={`accordion-item-${index}`}
                style={{
                  backgroundImage: `linear-gradient(rgba(36, 39, 58, 0.7), rgba(36, 39, 58, 0.7)), url(${project.background_image})`,
                  backgroundSize: "cover",
                }}
              >
                {/* <Accordion.Header></Accordion.Header> */}
                <StyledAccordion.Button
                  className="custom-accordion-button"
                  style={{
                    background: "transparent",
                    boxShadow: "none",
                  }}
                >
                  <span>{project.name}</span>
                </StyledAccordion.Button>
                <StyledAccordion.Body className="text-justify text-center text-light">
                  <p>{project.description}</p>
                  <a
                    href={project.registry_web_page}
                    target="_blank"
                    rel="noreferrer"
                    className="text-justify text-primary"
                  >
                    Click here to see the project
                  </a>
                </StyledAccordion.Body>
              </StyledAccordion.Item>
            );
          })}
        </StyledAccordion>
      </StyledContainer>
    </StyledContainer>
  );
}

export default SupportedProjectsList;
