import { ProjectsActions, ProjectsState } from "./types";

const initialState: ProjectsState = {
  pending: false,
  error: "",
  projects: [],
  supportedProjects: undefined,
};

const reducers = (state = initialState, action: ProjectsActions) => {
  switch (action.type) {
    //FOR ALL PROJECTS
    case "GET_PROJECTS_REQUEST":
      return {
        ...state,
        pending: true,
      };
    case "GET_PROJECTS_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case "GET_PROJECTS_SUCCESS":
      return {
        ...state,
        pending: false,
        error: null,
        projects: action.payload.projects,
      };
      //FOR SUPPORTED PROJECTS
    case "GET_SUPPORTED_PROJECTS_REQUEST":
      return {
        ...state,
        pending: true,
      };
    case "GET_SUPPORTED_PROJECTS_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case "GET_SUPPORTED_PROJECTS_SUCCESS":
      return {
        ...state,
        pending: false,
        error: null,
        supportedProjects: action.payload.supportedProjects,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducers;
