import userReducer from "./auth/user/user-slice";
import sessionReducer from "./auth/session/session-slice";
import vehicleReducer from "./vehicles/vehicles-slice";
import cartReducer from "./cart/cart-slice";
import projectsReducer from "./projects/projects-slice";
import brandsReducer from './brands/brands-slice';
import modelsReducer from './models/models-slice';
import salePricesReducer from './sale_prices/sale-prices-slice';
import lightModeReducer from './light_mode/light-mode-slice';

const rootReducer = {
  user: userReducer,
  session: sessionReducer,
  vehicles: vehicleReducer,
  cart: cartReducer,
  projects: projectsReducer,
  brands : brandsReducer,
  models: modelsReducer,
  sale_prices: salePricesReducer,
  light_mode: lightModeReducer,
};

export default rootReducer;
