import { all, call, put, takeLatest } from "redux-saga/effects";
import { supabase } from "app/supabase-client";
import {
  getUserSuccess,
  getUserFailure,
  resetUserSuccess,
  resetUserFailure,
} from "./actions";
import { GET_USER_REQUEST, RESET_USER_REQUEST } from "./actionTypes";
import { GetUserRequest, GetUserSuccessPayload } from "./types";

const getUser = async (id: string): Promise<GetUserSuccessPayload> => {
  try {
    let { data, error, status } = await supabase
      .from("profiles")
      .select(`*`)
      .eq("id", id)
      .single();

    if (error && status !== 406) {
      throw error;
    }
    if (data) {
      console.debug("getUser", data);
      return { user: data };
    } else throw new Error("No user found");
  } catch (error) {
    throw error;
  }
};

function* getUserSaga(action: GetUserRequest) {
  try {
    const user: GetUserSuccessPayload = yield call(() =>
      getUser(action.payload.user.id)
    );
    console.debug("getUserSaga", user);
    yield put(getUserSuccess(user));
  } catch (error: any) {
    yield put(getUserFailure({ error: error.message }));
  }
}

function* resetUserSaga() {
  try {
    yield put(resetUserSuccess());
  } catch (error: any) {
    yield put(resetUserFailure({ error: error.message }));
  }
}

function* userSaga() {
  yield all([takeLatest(GET_USER_REQUEST, getUserSaga)]);
  yield all([takeLatest(RESET_USER_REQUEST, resetUserSaga)]);
}

export default userSaga;
