import {
  Accordion,
  Button,
  Card,
  Container,
  FloatingLabel,
  FormControl,
  FormSelect,
  Modal,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import styled, { createGlobalStyle } from "styled-components";

export const darkTheme = {
  colors: {
    base: "#24273a",
    mantle: "#1e2030",
    crust: "#181926",
    text: "#cad3f5",
    subtext0: "#a5adcb",
    subtext1: "#b8c0e0",
    surface0: "#363a4f",
    surface1: "#494d64",
    surface2: "#5b6078",
    overlay0: "#6e738d",
    overlay1: "#8087a2",
    overlay2: "#939ab7",
    blue: "#8aadf4",
    lavender: "#b7bdf8",
    sapphire: "#7dc4e4",
    sky: "#91d7e3",
    teal: "#8bd5ca",
    green: "#a6da95",
    yellow: "#eed49f",
    peach: "#f5a97f",
    maroon: "#ee99a0",
    red: "#ed8796",
    mauve: "#c6a0f6",
    pink: "#f5bde6",
    flamingo: "#f0c6c6",
    rosewater: "#f4dbd6",
  },
  filters: {
    brightness: "brightness(200%)",
    invert: "invert(1)",
  },
};

export const lightTheme = {
  colors: {
    base: "#eff1f5",
    mantle: "#e6e9ef",
    crust: "#dce0e8",
    text: "#4c4f69",
    subtext0: "#6c6f85",
    subtext1: "#5c5f77",
    surface0: "#ccd0da",
    surface1: "#bcc0cc",
    surface2: "#acb0be",
    overlay0: "#9ca0b0",
    overlay1: "#8c8fa1",
    overlay2: "#7c7f93",
    blue: "#1e66f5",
    lavender: "#7287fd",
    sapphire: "#209fb5",
    sky: "#04a5e5",
    teal: "#179299",
    green: "#40a02b",
    yellow: "#df8e1d",
    peach: "#fe640b",
    maroon: "#e64553",
    red: "#d20f39",
    mauve: "#8839ef",
    pink: "#ea76cb",
    flamingo: "#dd7878",
    rosewater: "#dc8a78",
  },
  filters: {
    brightness: "brightness(.5)",
    invert: "invert(0)",
  },
};

export type Theme = typeof darkTheme;

// Custom Buttons
export const RedButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.red};
  border-color: ${(props) => props.theme.colors.red};
  color: ${(props) => props.theme.colors.base};
  &:hover {
    background-color: ${(props) => props.theme.colors.base};
    border-color: ${(props) => props.theme.colors.red};
    color: ${(props) => props.theme.colors.red};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(239, 105, 80, 0.5);
  }
  &:active {
    background-color: ${(props) => props.theme.colors.base} !important;
    border-color: ${(props) => props.theme.colors.red} !important;
    color: ${(props) => props.theme.colors.red} !important;
    box-shadow: none;
  }
  &:disabled {
    background-color: ${(props) => props.theme.colors.red};
    border-color: ${(props) => props.theme.colors.red};
    color: ${(props) => props.theme.colors.base};
    opacity: 0.65;
  }
`;

export const BlueButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.blue};
  border-color: ${(props) => props.theme.colors.blue};
  color: ${(props) => props.theme.colors.base};
  &:hover {
    background-color: ${(props) => props.theme.colors.base};
    border-color: ${(props) => props.theme.colors.blue};
    color: ${(props) => props.theme.colors.blue};
  }
  &:active {
    background-color: ${(props) => props.theme.colors.base} !important;
    border-color: ${(props) => props.theme.colors.blue} !important;
    color: ${(props) => props.theme.colors.blue} !important;
  }
  &:disabled {
    background-color: ${(props) => props.theme.colors.blue};
    border-color: ${(props) => props.theme.colors.blue};
    color: ${(props) => props.theme.colors.base};
    opacity: 0.65;
  }
`;

export const GreenButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.green};
  border-color: ${(props) => props.theme.colors.green};
  color: ${(props) => props.theme.colors.base};
  &:hover {
    background-color: ${(props) => props.theme.colors.base};
    border-color: ${(props) => props.theme.colors.green};
    color: ${(props) => props.theme.colors.green};
  }
  &:active {
    background-color: ${(props) => props.theme.colors.base} !important;
    border-color: ${(props) => props.theme.colors.green} !important;
    color: ${(props) => props.theme.colors.green} !important;
  }
  &:disabled {
    background-color: ${(props) => props.theme.colors.green};
    border-color: ${(props) => props.theme.colors.green};
    color: ${(props) => props.theme.colors.base};
    opacity: 0.65;
  }
`;

export const YellowButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.yellow};
  border-color: ${(props) => props.theme.colors.yellow};
  color: ${(props) => props.theme.colors.base};
  &:hover {
    background-color: ${(props) => props.theme.colors.base};
    border-color: ${(props) => props.theme.colors.yellow};
    color: ${(props) => props.theme.colors.yellow};
  }
  &:active {
    background-color: ${(props) => props.theme.colors.base} !important;
    border-color: ${(props) => props.theme.colors.yellow} !important;
    color: ${(props) => props.theme.colors.yellow} !important;
  }
  &:disabled {
    background-color: ${(props) => props.theme.colors.yellow};
    border-color: ${(props) => props.theme.colors.yellow};
    color: ${(props) => props.theme.colors.base};
    opacity: 0.65;
  }
`;

// Card
export const StyledCard = styled(Card)`
  background-color: ${(props) => props.theme.colors.mantle};
  border-color: ${(props) => props.theme.colors.mantle};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: ${(props) => props.theme.colors.text};

  & .card-header {
    background-color: ${(props) => props.theme.colors.mantle};
    border-color: ${(props) => props.theme.colors.mantle};
    color: ${(props) => props.theme.colors.text};
  }
  & .card-body {
    background-color: ${(props) => props.theme.colors.mantle};
    border-color: ${(props) => props.theme.colors.mantle};
    color: ${(props) => props.theme.colors.text};
  }
  & .card-footer {
    background-color: ${(props) => props.theme.colors.mantle};
    border-color: ${(props) => props.theme.colors.mantle};
    color: ${(props) => props.theme.colors.text};
  }
`;

// Container
export const StyledContainer = styled(Container)`
  background-color: ${(props) => props.theme.colors.base};
  color: ${(props) => props.theme.colors.text};
  & .text-primary {
    color: ${(props) => props.theme.colors.blue} !important;
  }
  & .text-secondary {
    color: ${(props) => props.theme.colors.subtext0} !important;
  }
  & .text-success {
    color: ${(props) => props.theme.colors.green} !important;
  }
  & .text-danger {
    color: ${(props) => props.theme.colors.red} !important;
  }
  & .text-warning {
    color: ${(props) => props.theme.colors.yellow} !important;
  }
  & .text-info {
    color: ${(props) => props.theme.colors.sky} !important;
  }
`;

// Modal
export const StyledModal = styled(Modal)`
  & .modal-content {
    background-color: ${(props) => props.theme.colors.base};
    color: ${(props) => props.theme.colors.text};
  }
  & .modal-header {
    background-color: ${(props) => props.theme.colors.base};
    color: ${(props) => props.theme.colors.text};
    border-bottom-color: ${(props) => props.theme.colors.crust};
  }
  & .modal-body {
    background-color: ${(props) => props.theme.colors.base};
    color: ${(props) => props.theme.colors.text};
  }
  & .modal-footer {
    background-color: ${(props) => props.theme.colors.base};
    color: ${(props) => props.theme.colors.text};
    border-top-color: ${(props) => props.theme.colors.crust};
  }
`;

// Navbar
export const StyledNavbar = styled(Navbar)`
  background-color: ${(props) => props.theme.colors.base};
  color: ${(props) => props.theme.colors.text};
  box-shadow: 0 0.3rem 0.25rem rgba(0, 0, 0, 0.1);

  & .navbar-brand {
    color: ${(props) => props.theme.colors.text};
  }
  & .navbar-nav .nav-link {
    color: ${(props) => props.theme.colors.text};
  }
  & .navbar-nav .nav-link:hover {
    color: ${(props) => props.theme.colors.blue};
  }
  & .navbar-nav .nav-link:focus {
    color: ${(props) => props.theme.colors.blue};
  }
  & .navbar-nav .nav-link:active {
    color: ${(props) => props.theme.colors.blue} !important;
  }
  & .offcanvas {
    background-color: ${(props) => props.theme.colors.base} !important;
    color: ${(props) => props.theme.colors.text};
  }
  & .offcanvas-header {
    background-color: ${(props) => props.theme.colors.base};
    color: ${(props) => props.theme.colors.text};
  }
  & .offcanvas-body {
    background-color: ${(props) => props.theme.colors.base};
    color: ${(props) => props.theme.colors.text};
  }
  & .offcanvas-footer {
    background-color: ${(props) => props.theme.colors.base};
    color: ${(props) => props.theme.colors.text};
  }
`;

// NavBar OffCanvas
export const StyledNavbarOffCanvas = styled(StyledNavbar.Offcanvas)`
  & .offcanvas-header {
    background-color: ${(props) => props.theme.colors.base};
    color: ${(props) => props.theme.colors.text};
  }
  & .offcanvas-body {
    background-color: ${(props) => props.theme.colors.base};
    color: ${(props) => props.theme.colors.text};
  }
`;

// Nav
export const StyledNav = styled(Nav)`
  color: ${(props) => props.theme.colors.text};
  & .nav-link {
    color: ${(props) => props.theme.colors.text};
  }
  & .nav-link:hover {
    background-color: ${(props) => props.theme.colors.blue};
    color: ${(props) => props.theme.colors.base} !important;
    font-weight: bold;
  }
  & .nav-link:focus {
    background-color: ${(props) => props.theme.colors.blue};
    color: ${(props) => props.theme.colors.base};
  }
`;

// NavBar Toggle
export const StyledNavbarToggle = styled(StyledNavbar.Toggle)`
  color: ${(props) => props.theme.colors.text};
  border: ${(props) => props.theme.colors.text};
  & .navbar-toggler-icon {
    filter: ${(props) => props.theme.filters.invert}
      ${(props) => props.theme.filters.brightness} !important;
  }
`;

// Dropdown
export const StyledNavDropdown = styled(NavDropdown)`
  & .dropdown-menu {
    background-color: ${(props) => props.theme.colors.base};
    color: ${(props) => props.theme.colors.text};
    border-color: ${(props) => props.theme.colors.mantle};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  & .dropdown-item {
    color: ${(props) => props.theme.colors.text};
  }
  & .dropdown-item:hover {
    color: ${(props) => props.theme.colors.text};
    background-color: ${(props) => props.theme.colors.mantle};
  }
`;

// Accordion
export const StyledAccordion = styled(Accordion)`
  color: ${(props) => props.theme.colors.text};
  & .custom-accordion-button span {
    color: white !important;
  }
  & .custom-accordion-button::after {
    filter: brightness(1000);
  }
  & .accordion-item {
    border-color: ${(props) => props.theme.colors.crust};
    border-width: 2px;
  }
  & .accordion-button {
    color: ${(props) => props.theme.colors.text};
  }
  & .accordion-body {
    color: ${(props) => props.theme.colors.text};

    & a {
      color: ${(props) => props.theme.colors.blue};
    }
  }
`;

// FormControl
export const StyledFormControl = styled(FormControl)`
  background-color: ${(props) => props.theme.colors.crust} !important;
  color: ${(props) => props.theme.colors.text} !important;
  border-color: ${(props) => props.theme.colors.crust} !important;
  & .form-control:focus {
    background-color: ${(props) => props.theme.colors.crust} !important;
    color: ${(props) => props.theme.colors.text} !important;
    border-color: ${(props) => props.theme.colors.crust} !important;
    box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.crust} !important;
  }
  & .form-control:active {
    background-color: ${(props) => props.theme.colors.crust} !important;
    color: ${(props) => props.theme.colors.text} !important;
    border-color: ${(props) => props.theme.colors.crust} !important;
    box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.crust} !important;
  }
`;

// Form Select
export const StyledFormSelect = styled(FormSelect)`
  background-color: ${(props) => props.theme.colors.crust} !important;
  color: ${(props) => props.theme.colors.text} !important;
  border-color: ${(props) => props.theme.colors.crust} !important;
  & .form-select:focus {
    background-color: ${(props) => props.theme.colors.crust} !important;
    color: ${(props) => props.theme.colors.text} !important;
    border-color: ${(props) => props.theme.colors.crust} !important;
    box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.crust} !important;
  }
  & .form-select:active {
    background-color: ${(props) => props.theme.colors.crust} !important;
    color: ${(props) => props.theme.colors.text} !important;
    border-color: ${(props) => props.theme.colors.crust} !important;
    box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.crust} !important;
  }
`;

// Floating Label
export const StyledFloatingLabel = styled(FloatingLabel)`
  color: ${(props) => props.theme.colors.text};
`;
// Global Style
export const GlobalStyle = createGlobalStyle`
    html {
        background-color: ${(props) => (props.theme as Theme).colors.base};
        color: ${(props) => (props.theme as Theme).colors.text};
        overflow-x: hidden;
        overflow-y: auto;
    }
    * {
        margin: 0;
        padding: 0;
    }
    *::-webkit-scrollbar {
        width: 10px;
        background-color: ${(props) => (props.theme as Theme).colors.base};
    }
    
    *::-webkit-scrollbar-track {
        background-color: ${(props) => (props.theme as Theme).colors.base};
    }
    
    *::-webkit-scrollbar-thumb {
        background-color: ${(props) => (props.theme as Theme).colors.green};
    }
    
    *::-webkit-scrollbar-corner {
        background-color: ${(props) => (props.theme as Theme).colors.base};
    }

    .nav-item {
        color: ${(props) => (props.theme as Theme).colors.text};
        background-color: ${(props) => (props.theme as Theme).colors.base};
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: ${(props) =>
          (props.theme as Theme).colors.blue} !important;
        border-color: ${(props) =>
          (props.theme as Theme).colors.blue} !important;
        color: ${(props) => (props.theme as Theme).colors.base} !important;
        font-weight: bold;
    }
    .nav-pills .nav-link {
        color: ${(props) => (props.theme as Theme).colors.blue} !important;
        border-color: ${(props) =>
          (props.theme as Theme).colors.blue} !important;
    }
    .text {
        color: ${(props) => (props.theme as Theme).colors.text} !important;
    }
    .text-primary {
        color: ${(props) => (props.theme as Theme).colors.blue} !important;
    }
    .text-light {
        color: white !important;
    }
    .text-dark {
        color: black !important;
    }
    .text-danger {
        color: ${(props) => (props.theme as Theme).colors.red} !important;
    }
    .text-muted {
        color: ${(props) => (props.theme as Theme).colors.subtext0} !important;
    }
    .text-success {
        color: ${(props) => (props.theme as Theme).colors.green} !important;
    }
    .text-warning {
        color: ${(props) => (props.theme as Theme).colors.yellow} !important;
    }
    .bg-warning {
        background-color: ${(props) =>
          (props.theme as Theme).colors.yellow} !important;
    }

    .custom-accordion-button span {
      color: white !important;
    }

    .custom-accordion-button::after {
        filter:brightness(1000);
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: 1px solid ${(props) => (props.theme as Theme).colors.crust};
      -webkit-text-fill-color: ${(props) => (props.theme as Theme).colors.text};
      -webkit-box-shadow: 0 0 0px 1000px ${(props) => (props.theme as Theme).colors.crust} inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    .btn-close {
      filter: ${(props) => (props.theme as Theme).filters.invert} !important;
      opacity: 1 !important;
    }
`;
