export const GET_SESSION_REQUEST = "GET_SESSION_REQUEST";
export const GET_SESSION_SUCCESS = "GET_SESSION_SUCCESS";
export const GET_SESSION_FAILURE = "GET_SESSION_FAILURE";

export const SET_SESSION_REQUEST = "SET_SESSION_REQUEST";
export const SET_SESSION_SUCCESS = "SET_SESSION_SUCCESS";
export const SET_SESSION_FAILURE = "SET_SESSION_FAILURE";

export const RESET_SESSION_REQUEST = "RESET_SESSION_REQUEST";
export const RESET_SESSION_SUCCESS = "RESET_SESSION_SUCCESS";
export const RESET_SESSION_FAILURE = "RESET_SESSION_FAILURE";

export const PASSWORD_RECOVERY_REQUEST = "PASSWORD_RECOVERY_REQUEST";
export const PASSWORD_RECOVERY_SUCCESS = "PASSWORD_RECOVERY_SUCCESS";
export const PASSWORD_RECOVERY_FAILURE = "PASSWORD_RECOVERY_FAILURE";
