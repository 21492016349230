import { BlueButton } from "app/theme";
import { BsFillCartCheckFill } from "react-icons/bs";
import CheckoutModal from "..";
import { useState } from "react";
import { ICartItem } from "features/cart/types";
import { ISalePrice } from "features/sale_prices/types";

function ButtonCheckout(props: {
  cartItems: ICartItem[];
  salePrices: ISalePrice[];
  checkout: () => any;
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <BlueButton onClick={handleShow} disabled={props.cartItems.length === 0}>
        Checkout Cart
        <BsFillCartCheckFill />
      </BlueButton>
      <CheckoutModal
        title={"Cart Checkout"}
        items={props.cartItems}
        salePrices={props.salePrices as ISalePrice[]}
        onCheckout={props.checkout}
        show={show}
        handleClose={handleClose}
        handleOpen={handleShow}
      ></CheckoutModal>
    </>
  );
}

export default ButtonCheckout;
