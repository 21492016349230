import { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import stripePromise from "app/stripe-client";
import CheckoutForm from "./CheckoutForm";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { StyledContainer, Theme } from "app/theme";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Spinner } from "react-bootstrap";
import {
  checkoutCartRequest,
  setClientSecretRequest,
} from "features/cart/actions";
import { ICartItem } from "features/cart/types";
import PageNotFound from "pages/NotFound/PageNotFound";
import { useTheme } from "styled-components";
import { RouterPath } from "app/router-path";

function Checkout() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  // const [clientSecret, setClientSecret] = useState("");
  const clientSecret = useAppSelector((state) => state.cart.clientSecret);
  const cartItems = useAppSelector((state) => state.cart.items) as ICartItem[];
  const session = useAppSelector((state) => state.session.session);
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    // Get the clientSecret from the URL (after the user is redirected from Stripe)
    const newClientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    // If there is a clientSecret in the URL, set it in the store and return.
    if (newClientSecret) {
      dispatch(setClientSecretRequest(newClientSecret as string));
      return;
    }
    // Create PaymentIntent only when the cartItems are loaded and there is no clientSecret and user has a valid session
    if (cartItems.length > 0 && !clientSecret && session)
      dispatch(
        checkoutCartRequest({
          session: session,
          checkoutType: "one-time",
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, session, clientSecret]);

  // Use the theme in a useEffect to avoid the unused variable error
  useEffect(() => {}, [theme]);

  const appearance = {
    theme: "flat",
    labels: "floating",
    variables: {
      colorPrimary: `${(theme as Theme).colors.blue}`,
      colorBackground: `${(theme as Theme).colors.mantle}`,
      colorText: `${(theme as Theme).colors.text}`,
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {clientSecret ? (
        <Elements
          options={options as StripeElementsOptions}
          stripe={stripePromise}
        >
          {session.user.email && user.username ? (
            <CheckoutForm
              return_url={`${process.env.REACT_APP_BASE_URL}${RouterPath.checkout}`}
            />
          ) : null}
        </Elements>
      ) : !clientSecret && cartItems.length === 0 ? (
        <>
          {/* If there is no clientSecret and the cart is empty
           that means the cart is empty and there is no redirect from a successful payment. 
           Show the 404 page */}
          <PageNotFound />
        </>
      ) : !clientSecret ? (
        <StyledContainer
          fluid
          style={{ padding: "80px 20px 20px 20px" }}
          className="text-center"
        >
          <Spinner animation="grow" />
        </StyledContainer>
      ) : null}
    </>
  );
}

export default Checkout;
