import {
  Badge,
  Nav,
  Offcanvas,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { RouterPath } from "../../app/router-path";
import { MenuDropDown } from "../MenuDropDown/MenuDropDown";
import { supabase } from "../../app/supabase-client";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  StyledContainer,
  StyledNavbar,
  StyledNavbarOffCanvas,
  StyledNavbarToggle,
} from "app/theme";
import { BsFillCartFill } from "react-icons/bs";
import NightModeToggle from "components/NightModeToggle";
import { resetUserRequest } from "features/auth/user/actions";
import { resetSessionRequest } from "features/auth/session/actions";
import { useNavigate } from "react-router-dom";

function NavBar() {
  const navigate = useNavigate();
  const session = useAppSelector((state) => state.session.session);
  const userRole = useAppSelector((state) => state.user.role);
  const cartItems = useAppSelector((state) => state.cart.items);
  const dispatch = useAppDispatch();
  const onLogoutClick = () => {
    supabase.auth.signOut();
    dispatch(resetUserRequest());
    dispatch(resetSessionRequest());
    navigate(RouterPath.home);
  };
  const USER_MENU_CONTENT = session
    ? [
        {
          title: "Profile",
          href: RouterPath.profile,
          children: null,
        },
        {
          title: "Vehicles",
          href: RouterPath.dashboard,
          children: null,
        },
        {
          title: "Settings",
          href: RouterPath.home,
          children: null,
        },
        { divider: true },
        {
          className: "text-danger",
          title: "Log Out",
          children: null,
          onClick: onLogoutClick,
        },
      ]
    : [
        {
          title: "Sign In/Up",
          href: RouterPath.login,
          children: null,
        },
      ];
  return (
    <StyledNavbar id="navbar" fixed="top" key={"lg"} expand={"lg"}>
      <StyledContainer>
        <StyledNavbar.Brand href={RouterPath.home}>
          Carbon Certificate
        </StyledNavbar.Brand>
        <StyledNavbarToggle aria-controls={`offcanvasNavbar-expand-${"lg"}`} />
        <StyledNavbarOffCanvas
          id={`offcanvasNavbar-expand-${"lg"}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${"lg"}`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"lg"}`}>
              <h3>Carbon Certificate</h3>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href={RouterPath.home}>Home</Nav.Link>
              {session && userRole !== "admin" ? (
                <Nav.Link href={RouterPath.myVehicles}>Dashboard</Nav.Link>
              ) : null}
              {session && userRole === "admin" ? (
                <Nav.Link href={RouterPath.admin}>Dashboard</Nav.Link>
              ) : null}
              <MenuDropDown menuContent={USER_MENU_CONTENT}></MenuDropDown>
              {session && userRole !== "admin" ? (
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-cart-bottom`}>
                      You have {cartItems.length} items in your cart
                    </Tooltip>
                  }
                >
                  <Nav.Link href={RouterPath.cart} className="text-warning">
                    <BsFillCartFill size={20} />{" "}
                    {cartItems && cartItems.length > 0 ? (
                      <Badge bg="warning" text="dark">
                        {cartItems.length}
                      </Badge>
                    ) : null}
                  </Nav.Link>
                </OverlayTrigger>
              ) : null}

              <NightModeToggle></NightModeToggle>
            </Nav>
          </Offcanvas.Body>
        </StyledNavbarOffCanvas>
      </StyledContainer>
    </StyledNavbar>
  );
}

export default NavBar;
