import { useAppSelector } from "app/hooks";
import { StyledCard, StyledContainer } from "app/theme";

function Profile() {
  const user = useAppSelector((state) => state.user.user);
  return (
    <>
      <StyledContainer fluid style={{ padding: "80px 20px 20px 20px" }}>
        <StyledCard style={{ width: "100%" }} className="text-center">
          <StyledCard.Header>
            <strong>
              <h4>Profile</h4>
            </strong>
          </StyledCard.Header>
          <StyledCard.Body>
            <StyledCard.Title>{user.username}</StyledCard.Title>
            <StyledCard.Subtitle className="mb-2 text-muted">
              {user.id}
            </StyledCard.Subtitle>
            <StyledCard.Text>
              <strong>Role:</strong> {user.role}
            </StyledCard.Text>
            <StyledCard.Link
              href="#"
              className="text-danger"
              // onClick={props.onRemove}
            >
              Delete Account
            </StyledCard.Link>
          </StyledCard.Body>
        </StyledCard>
      </StyledContainer>
    </>
  );
}

export default Profile;
