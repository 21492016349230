import { StyledContainer } from "app/theme";

function CheckoutSuccess(props: { message: string, description: string }) {
  return (
    <>
      <StyledContainer
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "95vh" }}
        fluid
      >
        <div className="text-center">
          <h1 className="display-1 fw-bold text-success">{props.message}</h1>
          <p className="fs-3">
            {" "}
            <span>{props.description}</span>
          </p>
        </div>
      </StyledContainer>
    </>
  );
}

export default CheckoutSuccess;
