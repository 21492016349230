import { all, call, put, takeLatest } from "redux-saga/effects";
import { supabase } from "app/supabase-client";
import {
  getSessionSuccess,
  getSessionFailure,
  resetSessionSuccess,
  resetSessionFailure,
  setSessionFailure,
  setSessionSuccess,
  passwordRecoverySuccess,
  passwordRecoveryFailure,
} from "./actions";
import { GET_SESSION_REQUEST, PASSWORD_RECOVERY_REQUEST, RESET_SESSION_REQUEST, SET_SESSION_REQUEST } from "./actionTypes";
import { GetSessionSuccessPayload, SetSessionRequest } from "./types";
import { Session } from "@supabase/gotrue-js";

const getSession = async (): Promise<Session | null> => {
  try {
    let response = await supabase.auth.getSession();

    if (response.error) {
      throw new Error(response.error.message);
    }
    return response.data.session;
  } catch (error) {
    throw error;
  }
};

function* getSessionSaga() {
  try {
    const session: GetSessionSuccessPayload = yield call(() => getSession());
    console.debug("getSessionSaga", session);
    yield put(getSessionSuccess(session));
  } catch (error: any) {
    yield put(getSessionFailure({ error: error.message }));
  }
}

function* setSessionSaga(action: SetSessionRequest) {
  try {
    yield put(setSessionSuccess(action.payload));
  } catch (error: any) {
    yield put(setSessionFailure({ error: error.message }));
  }
}

function* resetSessionSaga() {
  try {
    yield put(resetSessionSuccess());
  } catch (error: any) {
    yield put(resetSessionFailure({ error: error.message }));
  }
}

function* passwordRecoverySaga() {
  try {
    yield put(passwordRecoverySuccess());
  } catch (error: any) {
    yield put(passwordRecoveryFailure({ error: error.message }));
  }
}

function* sessionSaga() {
  yield all([takeLatest(GET_SESSION_REQUEST, getSessionSaga)]);
  yield all([takeLatest(SET_SESSION_REQUEST, setSessionSaga)]);
  yield all([takeLatest(RESET_SESSION_REQUEST, resetSessionSaga)]);
  yield all([takeLatest(PASSWORD_RECOVERY_REQUEST, passwordRecoverySaga)]);
}

export default sessionSaga;
