export const GET_VEHICLES_REQUEST = "GET_VEHICLES_REQUEST";
export const GET_VEHICLES_SUCCESS = "GET_VEHICLES_SUCCESS";
export const GET_VEHICLES_FAILURE = "GET_VEHICLES_FAILURE";

export const ADD_VEHICLE_REQUEST = "ADD_VEHICLE_REQUEST";
export const ADD_VEHICLE_SUCCESS = "ADD_VEHICLE_SUCCESS";
export const ADD_VEHICLE_FAILURE = "ADD_VEHICLE_FAILURE";

export const DELETE_VEHICLE_REQUEST = "DELETE_VEHICLE_REQUEST";
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS";
export const DELETE_VEHICLE_FAILURE = "DELETE_VEHICLE_FAILURE";

export const GET_VEHICLE_REQUEST = "GET_VEHICLE_REQUEST";
export const GET_VEHICLE_SUCCESS = "GET_VEHICLE_SUCCESS";
export const GET_VEHICLE_FAILURE = "GET_VEHICLE_FAILURE";


// export const UPDATE_VEHICLES_REQUEST = "UPDATE_VEHICLES_REQUEST";
// export const UPDATE_VEHICLES_SUCCESS = "UPDATE_VEHICLES_SUCCESS";
// export const UPDATE_VEHICLES_FAILURE = "UPDATE_VEHICLES_FAILURE";
