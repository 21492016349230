import { supabase } from "app/supabase-client";
import { ISalePrice } from "./types";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getSalePricesSuccess, getSalePricesFailure } from "./actions";
import { GET_SALE_PRICES_REQUEST } from "./actionTypes";

// Actions
const getSalePrices = async (): Promise<ISalePrice[]> => {
    try {
        const {data, error} = await supabase.rpc("get_all_sale_prices");
        if (error) {
            throw new Error(error.message);
        }
        console.debug("getSalePrices", data)
        return data;
    } catch (error: any) {
        throw new Error(error.message);
    }
};

function* getSalePricesSaga() {
    try {
        const salePrices: ISalePrice[] = yield call(() => getSalePrices());
        yield put(getSalePricesSuccess(salePrices));
    } catch (error) {
        yield put(getSalePricesFailure({ error: "Error getting sale prices." }));
    }
}

function* salePricesSaga() {
    yield all([takeLatest(GET_SALE_PRICES_REQUEST, getSalePricesSaga)]);
}

export default salePricesSaga;