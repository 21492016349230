import {
  // GET VEHICLES ACTION TYPES
  GET_VEHICLES_REQUEST,
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAILURE,
  // GET SINGLE VEHICULE ACTION TYPES
  GET_VEHICLE_REQUEST,
  GET_VEHICLE_SUCCESS,
  GET_VEHICLE_FAILURE,
  // DELETE VEHICLE ACTION TYPES
  DELETE_VEHICLE_REQUEST,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAILURE,
  // ADD VEHICLE ACTION TYPES
  ADD_VEHICLE_REQUEST,
  ADD_VEHICLE_SUCCESS,
  ADD_VEHICLE_FAILURE,
} from "./actionTypes";

import {
  //GET TYPES
  GetVehiclesRequest,
  GetVehiclesSuccess,
  GetVehiclesSuccessPayload,
  GetVehiclesFailure,
  GetVehiclesFailurePayload,

  //GET TYPES FOR SINGLE VEHICLE
  GetVehicleRequest,
  GetVehicleSuccess,
  GetVehicleSuccessPayload,
  GetVehicleFailure,
  GetVehicleFailurePayload,
  GetVehicleRequestPayload,

  //DELETE TYPES
  DeleteVehicleRequest,
  DeleteVehicleFailurePayload,
  DeleteVehicleSuccess,
  DeleteVehicleFailure,
  DeleteVehicleRequestPayload,

  //ADD TYPES
  AddVehicleRequest,
  AddVehicleFailurePayload,
  AddVehicleSuccess,
  AddVehicleFailure,
  AddVehicleRequestPayload,

  //ADD TYPES
} from "./types";

// GET VEHICLES ACTIONS

export const getVehiclesRequest = (): GetVehiclesRequest => ({
  type: GET_VEHICLES_REQUEST,
});

export const getVehiclesSuccess = (
  payload: GetVehiclesSuccessPayload
): GetVehiclesSuccess => ({
  type: GET_VEHICLES_SUCCESS,
  payload,
});

export const getVehiclesFailure = (
  payload: GetVehiclesFailurePayload
): GetVehiclesFailure => ({
  type: GET_VEHICLES_FAILURE,
  payload,
});

// GET SINGLE VEHICLE ACTION
export const getVehicleRequest = (
  payload: GetVehicleRequestPayload
): GetVehicleRequest => ({
  type: GET_VEHICLE_REQUEST,
  payload,
});

export const getVehicleSuccess = (
  payload: GetVehicleSuccessPayload
): GetVehicleSuccess => ({
  type: GET_VEHICLE_SUCCESS,
  payload,
});

export const getVehicleFailure = (
  payload: GetVehicleFailurePayload
): GetVehicleFailure => ({
  type: GET_VEHICLE_FAILURE,
  payload,
});

// DELETE VEHICLE ACTIONS

export const deleteVehicleRequest = (
  payload: DeleteVehicleRequestPayload
): DeleteVehicleRequest => ({
  type: DELETE_VEHICLE_REQUEST,
  payload,
});

export const deleteVehicleSuccess = (
  payload: string
): DeleteVehicleSuccess => ({
  type: DELETE_VEHICLE_SUCCESS,
  payload,
});

export const deleteVehicleFailure = (
  payload: DeleteVehicleFailurePayload
): DeleteVehicleFailure => ({
  type: DELETE_VEHICLE_FAILURE,
  payload,
});

// ADD VEHICLE ACTIONS

export const addVehicleRequest = (
  payload: AddVehicleRequestPayload
): AddVehicleRequest => ({
  type: ADD_VEHICLE_REQUEST,
  payload,
});

export const addVehicleSuccess = (): AddVehicleSuccess => ({
  type: ADD_VEHICLE_SUCCESS,
});

export const addVehicleFailure = (
  payload: AddVehicleFailurePayload
): AddVehicleFailure => ({
  type: ADD_VEHICLE_FAILURE,
  payload,
});
