import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./rootReducer";
import { rootSaga } from "./rootSaga";
import thunk from "redux-thunk";
import { CurriedGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, thunk];

export const store = configureStore({
  reducer: rootReducer as any,
  middleware: ((getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares)) as any,
});

// This is the type returned by `configureStore`
export type AppDispatch = typeof store.dispatch;

// This is the type of the `store` itself
export type RootState = ReturnType<typeof store.getState>;

// Path: src/app/store.ts

// Run the saga
sagaMiddleware.run(rootSaga);

export default store;
