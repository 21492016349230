import { FaUserCircle } from "react-icons/fa";
import { DropdownItemProps } from "react-bootstrap/esm/DropdownItem";
import { StyledNavDropdown } from "app/theme";

// Props interface
interface MenuElement extends DropdownItemProps {
  divider?: boolean;
  onClick?: () => void;

}

interface Props {
  menuContent: MenuElement[]
};

export function MenuDropDown(props: Props) {
  return (
    <>
      <StyledNavDropdown
        title={
          <>
            <FaUserCircle size={25} />
          </>
        }
        id="collasible-nav-dropdown"
      >
        { props.menuContent.map((item, index) => {
            return (
              item.divider ? 
                <StyledNavDropdown.Divider key={ index } /> : 
                <StyledNavDropdown.Item {...item} key={ index }>
                  { item.title }
                </StyledNavDropdown.Item>
            )
          })
        }
    </StyledNavDropdown>
    </>
  );
}
