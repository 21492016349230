import {
  GreenButton,
  RedButton,
  StyledCard,
  StyledModal,
} from "app/theme";
import { ICartItem } from "features/cart/types";
import { ISalePrice } from "features/sale_prices/types";
import { Col, Row } from "react-bootstrap";

function CheckoutModal(props: {
  show: boolean;
  title: string;
  items: ICartItem[];
  salePrices: ISalePrice[];
  onCheckout: () => void;
  handleClose: () => void;
  handleOpen: () => void;
}) {
  const salePrice = (item: ICartItem) => {
    let salePrice = 0;
    props.salePrices.forEach((salePriceItem) => {
      if (salePriceItem.project === item.project) {
        salePrice = salePriceItem.price * parseFloat(item.credits);
      }
    });
    return salePrice;
  };

  const totalCredits = () => {
    let total = 0;
    props.items.forEach((item) => {
      total += parseFloat(item.credits);
    });
    return total;
  };

  const totalSalePrice = () => {
    let total = 0;
    props.items.forEach((item) => {
      total += salePrice(item);
    });
    return total;
  };

  return (
    <>
      <StyledModal
        show={props.show}
        onHide={props.handleClose}
        centered
        fullscreen={true}
      >
        <StyledModal.Header closeButton>
          <StyledModal.Title>{props.title}</StyledModal.Title>
        </StyledModal.Header>
        <StyledModal.Body>
          {props.items.map((item: ICartItem, index: number) => (
            <StyledCard key={`cart-item-${index}`} className="mt-2 p-3 m-3">
              <StyledCard.Title>Transaction #{index + 1}</StyledCard.Title>
              <StyledCard.Body style={{ padding: 0 }}>
                <Row>
                  <Col md={6}>
                    <h5 className="text-primary">
                      <strong>{item.project_name}</strong>
                    </h5>
                    <h5 className="text-muted">{item.vehicle}</h5>
                    <h5>
                      <span className="text-success">{item.credits}</span>
                      <span> tCO2eq / </span>
                      <span className="text-success">
                        {parseFloat(item.credits) * 1000}{" "}
                      </span>
                      {" kgCO2eq"}
                    </h5>
                  </Col>
                  <Col md={6} className="text-end align-middle">
                    <h5 className="text-warning">${salePrice(item)} USD</h5>
                  </Col>
                </Row>
              </StyledCard.Body>
            </StyledCard>
          ))}
          <StyledCard className="mt-2 p-3 m-3 d-flex justify-content-between">
            <StyledCard.Title style={{ marginBottom: 0 }}>
              <Row>
                <Col md={6}>
                  <span>Total : </span>
                  <span className="text-success">
                    {
                      // Calculate total credits from all items in cart
                      totalCredits()
                    }
                  </span>
                  <span> tCO2eq / </span>
                  <span className="text-success">
                    {
                      // Calculate total credits from all items in cart
                      totalCredits() * 1000
                    }
                  </span>
                  <span> kgCO2eq</span>
                </Col>
                <Col md={6} className="text-end">
                  <span className="text-warning">
                    Total : ${totalSalePrice()} USD
                  </span>
                </Col>
              </Row>
            </StyledCard.Title>
          </StyledCard>
        </StyledModal.Body>
        <StyledModal.Footer>
          <RedButton onClick={props.handleClose} className="mt-3">
            Close
          </RedButton>
          <GreenButton
            onClick={() => {
              props.onCheckout();
              props.handleClose();
            }}
            className="mt-3"
          >
            Pay now
          </GreenButton>
        </StyledModal.Footer>
      </StyledModal>
    </>
  );
}

export default CheckoutModal;
