import { Spinner, Form } from "react-bootstrap";
import { supabase } from "app/supabase-client";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  BlueButton,
  StyledCard,
  StyledFloatingLabel,
  StyledFormControl,
} from "app/theme";
import { useNavigate } from "react-router-dom";
import { RouterPath } from "app/router-path";

export function PasswordRecovery() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();

  const UpdatePassword = async (userData: any) => {
    try {
      setLoading(true);
      if (userData.password !== userData.confirmPassword) {
        alert("Password does not match");
        return;
      }
      const { data, error } = await supabase.auth.updateUser({
        password: userData.password,
      });
      if (data) {
        alert("Password updated successfully");
        navigate(RouterPath.home);
      }
      if (error) {
        alert(error);
        return;
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <StyledCard
        style={{
          padding: "10px",
          width: "80%",
        }}
      >
        <StyledCard.Header>
          <h3>
            <strong>New Password</strong>
          </h3>
        </StyledCard.Header>
        <StyledCard.Body>
          {loading ? (
            <BlueButton disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading, please wait...
            </BlueButton>
          ) : (
            <Form
              onSubmit={handleSubmit((data) => {
                UpdatePassword(data);
              })}
            >
              <StyledFloatingLabel label="Password" className="mb-3">
                <StyledFormControl
                  id="password"
                  className="inputField"
                  type="password"
                  placeholder="Your password"
                  {...register("password", { required: true })}
                />
              </StyledFloatingLabel>

              <StyledFloatingLabel label="Confirm Password" className="mb-3">
                <StyledFormControl
                  id="confirmPassword"
                  className="inputField"
                  type="password"
                  placeholder="Confirm your password"
                  {...register("confirmPassword", { required: true })}
                />
              </StyledFloatingLabel>

              <BlueButton type="submit" className="primary" aria-live="polite">
                Save
              </BlueButton>
            </Form>
          )}
        </StyledCard.Body>
      </StyledCard>
    </>
  );
}
