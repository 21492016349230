import { BrandsActions, BrandsState } from "./types";

const initialState: BrandsState = {
  pending: false,
  error: "",
  brands: [],
};

const reducers = (state = initialState, action: BrandsActions) => {
  switch (action.type) {
    //FOR ALL BRANDS
    case "GET_BRANDS_REQUEST":
      return {
        ...state,
        pending: true,
      };
    case "GET_BRANDS_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case "GET_BRANDS_SUCCESS":
      return {
        ...state,
        pending: false,
        error: null,
        brands: action.payload.brands,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducers;
