import { RouterPath } from "app/router-path";
import { StyledContainer, StyledNav } from "app/theme";

function DashboardMenu() {
  // Function to get the current URL path (ex: /dashboard/vehicles) and return the default active key
  const getDefaultActiveKey = () => {
    const currentPath = window.location.pathname;
    switch (currentPath) {
      case RouterPath.myVehicles:
        return RouterPath.myVehicles;
      case RouterPath.mySupportedProjects:
        return RouterPath.mySupportedProjects;
      case RouterPath.cart:
        return RouterPath.cart;
      default:
        return RouterPath.myVehicles;
    }
  };

  return (
    <StyledContainer fluid>
      <StyledNav
        justify
        fill
        variant="pills"
        defaultActiveKey={getDefaultActiveKey()}
      >
        <StyledNav.Item>
          <StyledNav.Link href={RouterPath.myVehicles}>
            My Vehicles
          </StyledNav.Link>
        </StyledNav.Item>
        <StyledNav.Item>
          <StyledNav.Link href={RouterPath.mySupportedProjects}>
            My Supported Projects
          </StyledNav.Link>
        </StyledNav.Item>
        {/* <StyledNav.Item>
          <StyledNav.Link href={RouterPath.cart}>Cart</StyledNav.Link>
        </StyledNav.Item>
        <StyledNav.Item>
          <StyledNav.Link href={RouterPath.profile} disabled>Profile</StyledNav.Link>
        </StyledNav.Item> */}
      </StyledNav>
    </StyledContainer>
  );
}

export default DashboardMenu;
