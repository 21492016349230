import { useCallback, useEffect, useState } from "react";
import { supabase } from "../../app/supabase-client";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_Row,
  MaterialReactTableProps,
} from "material-react-table";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Refresh } from "@mui/icons-material";
import { FaPlus } from "react-icons/fa";
import CreateModal from "./CreateModal";
import {
  brands,
  carbon_certificates,
  credit_transactions,
  makes,
  models,
  profiles,
  projects,
  registries,
  roles,
} from "../../app/types";

type tableData =
  | carbon_certificates[]
  | credit_transactions[]
  | profiles[]
  | projects[]
  | registries[]
  | roles[]
  | models[]
  | brands[];

type rowData =
  | carbon_certificates
  | credit_transactions
  | makes
  | profiles
  | projects
  | registries
  | roles;

// TO DO: USE REDUX INSTEAD OF STATE for tableData

function DataTableEditor(props: { model: string }) {
  // Data for the table
  const [tableData, setTableData] = useState<tableData>([]);
  // Columns for the table
  const [columns, setColumns] = useState<MRT_ColumnDef<any>[]>([]);
  // Loading state for the table
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Validation errors for the table
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  // Create modal state
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  // Fetch data from supabase
  const getData = async () => {
    setIsLoading(true);
    try {
      const { data: model_data, error } = await supabase
        .from(props.model)
        .select("*");
      if (error) throw error;
      if (model_data) {
        setColumns(generateColumns(model_data));
        setTableData([...model_data]);
      }
    } catch (error) {
      alert("error : " + error);
    } finally {
      setIsLoading(false);
    }
  };
  // Delete row in supabase
  const deleteRow = async (row: rowData): Promise<boolean> => {
    console.debug("deleteRow", row);
    setIsLoading(true);
    let success = false;
    try {
      const { error } = await supabase
        .from(props.model)
        .delete()
        .eq("id", row.id);
      console.debug(row.id);
      if (error) throw error;
      success = true;
    } catch (error) {
      alert("error : " + error);
      console.debug(error);
    } finally {
      setIsLoading(false);
    }
    return success;
  };
  // Update row in supabase
  const updateRow = async (row: rowData): Promise<boolean> => {
    console.debug("updateRow", row);
    setIsLoading(true);
    let success = false;
    try {
      const { error } = await supabase
        .from(props.model)
        .update(row)
        .eq("id", row.id);
      if (error) throw error;
      success = true;
    } catch (error) {
      alert("error : " + error);
    } finally {
      setIsLoading(false);
    }
    return success;
  };

  // Insert row in supabase
  const insertRow = async (row: rowData): Promise<boolean> => {
    setIsLoading(true);
    let success = false;
    try {
      const { data: inserted_row, error } = await supabase
        .from(props.model)
        .insert(row)
        .select("*");
      if (error) throw error;
      if (inserted_row) {
        setTableData([...tableData, inserted_row[0]]);
        success = true;
      }
    } catch (error) {
      alert("error : " + error);
    } finally {
      setIsLoading(false);
    }
    return success;
  };

  // Generate table columns (headers) from fetched data with some exceptions handled as well
  const generateColumns = (data: any) => {
    const columns = [];
    for (const value of data) {
      for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          let columnConfig = {
            accessorKey: key,
            header: key,
            enableResizing: true,
            enableClickToCopy: true,
            enableColumnDragging: true,
          } as MRT_ColumnDef<any>;
          if (key === "id" || key === "created_at" || key === "updated_at")
            columnConfig = {
              ...columnConfig,
              enableEditing: false,
            };
          else if (key === "role")
            columnConfig = {
              ...columnConfig,
              editVariant: "select",
              editSelectOptions: [
                { value: "admin", text: "admin" },
                { value: "client", text: "client" },
                { value: "sales", text: "sales" },
                { value: "buyer", text: "buyer" },
              ],
            };
          columns.push(columnConfig);
        }
      }
      break;
    }
    return columns;
  };

  // Handle row edits
  const handleSaveRowEdits: MaterialReactTableProps<any>["onEditingRowSave"] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        tableData[row.index] = values;
        //send/receive api updates here, then refetch or update local table data for re-render
        if (await updateRow(values)) setTableData([...tableData]);
        exitEditingMode(); //required to exit editing mode and close modal
      }
    };
  // Handle row edits cancel
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  // Handle row deletion
  const handleDeleteRow = useCallback(
    async (row: MRT_Row<any>) => {
      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(`Are you sure you want to delete ${row.getValue("id")}`)
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      if (await deleteRow(row.original)) tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableData]
  );

  const handleCreateNewRow = (values: rowData) => {
    insertRow(values);
    setCreateModalOpen(false);
  };

  useEffect(() => {
    // First time fetch data
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        enableRowSelection
        enableColumnOrdering
        enableColumnDragging
        enableEditing
        editingMode="modal"
        state={{ isLoading: isLoading }}
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        onPaginationChange={() => {}}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "0.5rem" }}>
            <Tooltip title={`Edit ${row.original.id}`}>
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>

            <Tooltip title={`Delete ${row.original.id}`}>
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <Tooltip title={`Create new ${props.model}`}>
                <Button
                  color="success"
                  onClick={() => setCreateModalOpen(true)}
                  variant="contained"
                >
                  <FaPlus />
                </Button>
              </Tooltip>

              <Tooltip title={`Refresh ${props.model} data`}>
                <Button color="primary" onClick={getData} variant="contained">
                  <Refresh />
                </Button>
              </Tooltip>
            </Box>
          </>
        )}
      />
      <CreateModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
        model={props.model}
      />
    </>
  );
}

export default DataTableEditor;
