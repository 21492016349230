export const RouterPath = {
    home: '/',
    login: '/login/',
    passwordRecovery: '/password-recovery/',
    dashboard: '/dashboard/',
    admin: '/admin/',
    vehicle: '/vehicle/',
    myVehicles: '/dashboard/vehicles/',
    mySupportedProjects: '/dashboard/supported-projects/',
    profile: '/profile/',
    cart: '/cart/',
    checkout: '/cart/checkout/',
    checkoutSubscription: '/cart/checkout/subscription/',
};
