import { StyledContainer } from "app/theme";

function PageNotFound() {
  return (
    <>
      <StyledContainer
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "95vh" }}
        fluid
      >
        <div className="text-center">
          <h1 className="display-1 fw-bold">404</h1>
          <p className="fs-3">
            {" "}
            <span className="text-danger">Oops!</span> Unknown Page.
          </p>
          <p>
            The page you’re looking for doesn’t exist.
          </p>
        </div>
      </StyledContainer>
    </>
  );
}

export default PageNotFound;