import { all, call, put, takeLatest } from "redux-saga/effects";
import { supabase } from "app/supabase-client";
import { PostgrestError } from "@supabase/supabase-js";
import { getBrandsSuccess, getBrandsFailure} from "./actions";
import { GET_BRANDS_REQUEST } from "./actionTypes";
import { GetBrandsSuccessPayload} from "./types";

// all brands
const getBrands = async (): Promise<GetBrandsSuccessPayload> => {
  const request = await supabase.rpc("get_brands");

  if (request.error) {
    console.debug("getBrands", request);
    throw request.error;
  }

  return { brands: request.data };
};

function* getBrandsSaga() {
  try {
    const brands: GetBrandsSuccessPayload = yield call(() => getBrands());
    console.debug("getBrandsSaga", brands);
    yield put(getBrandsSuccess(brands));
  } catch (error) {
    yield put(getBrandsFailure({ error: (error as PostgrestError).message }));
  }
}

//this is basically the watcher for every brands requests
function* brandsSaga() {
  yield all([takeLatest(GET_BRANDS_REQUEST, getBrandsSaga)]);
}

export default brandsSaga;
