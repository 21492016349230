import { VehiclesActions, VehiclesState } from "./types";

const initialState: VehiclesState = {
  pending: false,
  error: "",
  vehicles: [], 
  targetVehicle: undefined,
  success: ""
};

const reducers = (state = initialState, action: VehiclesActions) => {
  switch (action.type) {
    case "GET_VEHICLES_REQUEST":
      return {
        ...state,
        pending: true,
      };
    case "GET_VEHICLES_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case "GET_VEHICLES_SUCCESS":
      return {
        ...state,
        pending: false,
        error: null,
        vehicles: action.payload.vehicles,
      };
      case "GET_VEHICLE_REQUEST":
      return {
        ...state,
        pending: true,
      };
    case "GET_VEHICLE_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case "GET_VEHICLE_SUCCESS":
      return {
        ...state,
        pending: false,
        error: null,
        targetVehicle: action.payload.vehicle,
      };
    case "ADD_VEHICLE_REQUEST":
      return {
        ...state,
        pending: true,
        success: "",
        error: ""
      };
    case "ADD_VEHICLE_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        sucess: ""
      };
    case "ADD_VEHICLE_SUCCESS":
      return {
        ...state,
        pending: false,
        error: null,
        success: "Vehicle added successfully"
      };
    case "DELETE_VEHICLE_REQUEST":
      return {
        ...state,
        pending: true,
        success: "",
        error: ""
      };
    case "DELETE_VEHICLE_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        sucess: ""
      };
    case "DELETE_VEHICLE_SUCCESS":
      return {
        ...state,
        pending: false,
        error: null,
        vehicles: state.vehicles.filter(
          (vehicle) => vehicle.id !== action.payload
        ),
        success: "Vehicle deleted successfully"
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducers;
