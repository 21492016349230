import { CartActions, CartState } from "./types";

const initialState: CartState = {
  pending: false,
  items: [],
  error: "",
  success: "",
  clientSecret: "",
};

const reducers = (state = initialState, action: CartActions) => {
  switch (action.type) {
    case "GET_CART_REQUEST":
      return {
        ...state,
        pending: true,
      };
    case "GET_CART_SUCCESS":
      return {
        ...state,
        pending: false,
        error: null,
        items: action.payload.items,
      };
    case "GET_CART_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case "ADD_CART_ITEM_REQUEST":
      return {
        ...state,
        pending: true,
        success: "",
        error: ""
      };
    case "ADD_CART_ITEM_SUCCESS":
      return {
        ...state,
        pending: false,
        error: null,
        items: action.payload.items,
        success: `Item added to cart` 
      };
    case "ADD_CART_ITEM_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        success: ""
      };
    case "DELETE_CART_REQUEST":
      return {
        ...state,
        pending: true,
        success: "",
      };
    case "DELETE_CART_SUCCESS":
      return {
        ...state,
        pending: false,
        error: null,
        items: [],
        success: "Cart deleted"
      };
    case "DELETE_CART_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        success: "",
      };
    case "REMOVE_CART_ITEM_REQUEST":
      return {
        ...state,
        pending: true,
      };
    case "REMOVE_CART_ITEM_SUCCESS":
      return {
        ...state,
        pending: false,
        items: state.items.filter(
          (item) => JSON.stringify(item) !== JSON.stringify(action.payload.item)
        ),
      };
    case "REMOVE_CART_ITEM_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        success: ""
      };
    case "CHECKOUT_CART_ITEM_REQUEST":
      return {
        ...state,
        pending: true,
        success: "",
        error: ""
      };
    case "CHECKOUT_CART_ITEM_SUCCESS":
      return {
        ...state,
        pending: false,
        success: "Checkout successful",
      };
    case "CHECKOUT_CART_ITEM_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
        success: ""
      };
    case "CHECKOUT_CART_REQUEST":
      return {
        ...state,
        pending: true,
        error: ""
      }
    case "CHECKOUT_CART_SUCCESS":
      return {
        ...state,
        pending: false,
        clientSecret: action.payload
      };
    case "CHECKOUT_CART_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case "SET_CLIENT_SECRET_REQUEST": 
      return {
        ...state,
        pending: true,
        error: ""
      };
    case "SET_CLIENT_SECRET_SUCCESS":
      return {
        ...state,
        pending: false,
        clientSecret: action.payload
      };
    case "SET_CLIENT_SECRET_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducers;
