// import
import { all, fork } from "redux-saga/effects";

import vehicleSaga from "./vehicles/saga";
import projectSaga from "./projects/saga";
import brandSaga from "./brands/saga";
import modelSaga from "./models/saga";
import cartSaga from "./cart/saga";
import salePricesSaga from "./sale_prices/saga";
import lightModeSaga from "./light_mode/saga";
import userSaga from "./auth/user/saga";
import sessionSaga from "./auth/session/saga";

export function* rootSaga() {
  yield all([fork(userSaga)]); // fork means run in parallel
  yield all([fork(sessionSaga)]);
  yield all([fork(vehicleSaga)]);
  yield all([fork(projectSaga)]);
  yield all([fork(brandSaga)]);
  yield all([fork(modelSaga)]);
  yield all([fork(cartSaga)]);
  yield all([fork(salePricesSaga)]);
  yield all([fork(lightModeSaga)]);
}
