import { all, call, put, takeLatest } from "redux-saga/effects";
import { supabase } from "app/supabase-client";
import { PostgrestError } from "@supabase/supabase-js";
import { getModelsSuccess, getModelsFailure} from "./actions";
import { GET_MODELS_REQUEST } from "./actionTypes";
import { GetModelsRequest, GetModelsSuccessPayload} from "./types";

// all models
const getModels = async (id: string): Promise<GetModelsSuccessPayload> => {
  const request = await supabase.rpc("get_models", { _brand_id: id });

  if (request.error) {
    console.debug("getModels", request);
    throw request.error;
  }

  return { models: request.data };
};

function* getModelsSaga(action: GetModelsRequest) {
  try {
    const models: GetModelsSuccessPayload = yield call(() => getModels(action.payload.id));
    console.debug("getModelsSaga", models);
    yield put(getModelsSuccess(models));
  } catch (error) {
    yield put(getModelsFailure({ error: (error as PostgrestError).message }));
  }
}

//this is basically the watcher for every models requests
function* modelsSaga() {
  yield all([takeLatest(GET_MODELS_REQUEST, getModelsSaga)]);
}

export default modelsSaga;
