import { ModelsActions, ModelsState } from "./types";

const initialState: ModelsState = {
  pending: false,
  error: "",
  models: [],
};

const reducers = (state = initialState, action: ModelsActions) => {
  switch (action.type) {
    //FOR ALL MODELS
    case "GET_MODELS_REQUEST":
      return {
        ...state,
        pending: true,
      };
    case "GET_MODELS_FAILURE":
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case "GET_MODELS_SUCCESS":
      return {
        ...state,
        pending: false,
        error: null,
        models: action.payload.models,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducers;
