import { useAppSelector } from "app/hooks";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const StyledAlert = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.red};
  z-index: 999;
  transition: all 0.5s ease-in-out;
  color: ${(props) => props.theme.colors.base};
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slide-in 0.3s ease-in-out;

  @keyframes slide-in {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: ${(props) => props.theme.colors.base};
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
`;

function Alert() {
  const [display, setDisplay] = useState(false);

  const cartError = useAppSelector((state) => state.cart.error);
  const vehiclesError = useAppSelector((state) => state.vehicles.error);
  const projectsError = useAppSelector((state) => state.projects.error);

  const errors = useMemo(() => {
    return [cartError, vehiclesError, projectsError];
  }, [cartError, vehiclesError, projectsError]);

  useEffect(() => {
    // Loop through all errors, if any of them are true, set display to true
    for (let i = 0; i < errors.length; i++) {
      if (errors[i]) {
        setDisplay(true);
        setTimeout(() => {
          setDisplay(false);
        }, 3000);
        break;
      }
    }
  }, [errors]);

  return (
    <>
      {display ? (
        <StyledAlert>
          {errors.map((error: any, index: number) => {
            return error ? (
              <b key={`error-${index}`}>
                {error}
                <br></br>
              </b>
            ) : null;
          })}
          <CloseButton onClick={() => setDisplay(false)}>✗</CloseButton>
        </StyledAlert>
      ) : null}
    </>
  );
}

export default Alert;
