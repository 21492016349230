import { Container, Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect } from "react";
import { IoMdTrash } from "react-icons/io";
import { TbCertificate } from "react-icons/tb";
import { RouterPath } from "app/router-path";
import BuyCreditModal from "./BuyCreditModal";
import AddVehicleModal from "./AddVehicleModal";
import {
  getVehiclesRequest,
  deleteVehicleRequest,
} from "features/vehicles/actions";
import { IVehicle } from "features/vehicles/types";
import { GreenButton, RedButton, StyledCard, StyledContainer } from "app/theme";
import DashboardMenu from "components/DashboardMenu";
//import { Theme } from "app/theme";

function VehicleList() {
  //const theme = useTheme() as Theme;
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.user.user.id);
  const vehicles = useAppSelector(
    (state) => state.vehicles.vehicles
  ) as IVehicle[];

  // Get vehicles list from supabase
  const getVehicles = async () => {
    dispatch(getVehiclesRequest());
  };

  // Delete vehicle
  const deleteVehicle = async (id: string) => {
    console.debug("deleteVehicle", id);
    dispatch(deleteVehicleRequest({ id: id }));
  };

  useEffect(() => {
    if (userId) getVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <StyledContainer fluid style={{padding: "80px 20px 20px 20px"}}>
      <DashboardMenu></DashboardMenu>
      <Container className="mb-3 d-flex mt-3" id="toolbar" fluid>
        {userId ? <AddVehicleModal></AddVehicleModal> : <></>}
      </Container>
      <StyledContainer fluid>
        <Row id="vehicles-card-list" className="text-center">
          {vehicles.map((vehicle: IVehicle) => (
            <Col key={vehicle.vin} sm="6">
              <StyledCard style={{ width: "100%" }} className="mb-3">
                <StyledCard.Body>
                  <StyledCard.Title>{vehicle.model_name}</StyledCard.Title>
                  <StyledCard.Text>
                    {vehicle.brand_name} <br></br>
                    Number Plate : {vehicle.number_plate}
                    <br></br>
                    VIN : {vehicle.vin}
                  </StyledCard.Text>
                </StyledCard.Body>
                <StyledCard.Footer className="d-flex justify-content-evenly">
                  {userId ? (
                    <BuyCreditModal
                      vehicle={vehicle.model_name as string}
                      vehicleId={vehicle.id as string}
                    ></BuyCreditModal>
                  ) : (
                    <></>
                  )}
                  <RedButton
                    onClick={() => {
                      if (
                        // eslint-disable-next-line no-restricted-globals
                        !confirm(
                          `Are you sure you want to delete this vehicle?`
                        )
                      )
                        return;

                      deleteVehicle(vehicle.id as string);
                    }}
                  >
                    <IoMdTrash />
                  </RedButton>
                  <GreenButton
                    href={`${RouterPath.vehicle}${vehicle.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TbCertificate />
                  </GreenButton>
                </StyledCard.Footer>
              </StyledCard>
            </Col>
          ))}
        </Row>
      </StyledContainer>
    </StyledContainer>
  );
}

export default VehicleList;
