import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  getCartSuccess,
  getCartFailure,
  addCartItemSuccess,
  addCartItemFailure,
  deleteCartSuccess,
  deleteCartFailure,
  removeCartItemSuccess,
  removeCartItemFailure,
  checkoutCartItemSuccess,
  checkoutCartSuccess,
  checkoutCartFailure,
  setClientSecretSuccess,
  setClientSecretFailure,
} from "./actions";

import {
  ADD_CART_ITEM_REQUEST,
  CHECKOUT_CART_ITEM_REQUEST,
  CHECKOUT_CART_REQUEST,
  DELETE_CART_REQUEST,
  GET_CART_REQUEST,
  REMOVE_CART_ITEM_REQUEST,
  SET_CLIENT_SECRET_REQUEST,
} from "./actionTypes";

import {
  AddCartItemRequest,
  AddCartItemSuccessPayload,
  GetCartSuccessPayload,
  ICartItem,
  RemoveCartItemRequest,
  DeleteCartSuccessPayload,
  RemoveCartItemSuccessPayload,
  CheckoutCartItemRequest,
  CheckoutCartRequest,
  SetClientSecretRequest,
  CheckoutType,
} from "./types";
import Cookies from "js-cookie";
import Ajv from "ajv";
import { cartJsonSchema, cartItemJsonSchema } from "./CartItemJsonSchema";
import { supabase } from "app/supabase-client";
import { Session } from "@supabase/gotrue-js";

const ajv = new Ajv();
// Actions

const getCart = async (): Promise<GetCartSuccessPayload> => {
  try {
    const cart = Cookies.get("cart");
    if (cart) {
      // JSON Validation
      const validate = ajv.compile(cartJsonSchema);
      const valid = validate(JSON.parse(cart));
      if (!valid) {
        console.error("Invalid cart JSON: ", validate.errors);
        Cookies.remove("cart");
        throw new Error("Invalid cart JSON");
      }
      return JSON.parse(cart);
    } else {
      return { items: [] };
    }
  } catch (error) {
    console.error(error);
    throw new Error("Error getting cart");
  }
};

function* getCartSaga() {
  try {
    const cart: GetCartSuccessPayload = yield call(() => getCart());
    console.debug("getCartSaga", cart);
    yield put(getCartSuccess(cart));
  } catch (error) {
    yield put(getCartFailure({ error: "Error getting cart." }));
  }
}
const checkCreditAvailability = async (
  project_id: string,
  credits_used: string
): Promise<boolean> => {
  try {
    const request = await supabase.rpc("check_credit_availability", {
      project_id: project_id,
      credits_used: credits_used,
    });
    if (request.error) {
      throw new Error(request.error.message);
    }
    return request.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
const addCartItem = async (item: ICartItem) => {
  // First check Credits amount
  if (await checkCreditAvailability(item.project, item.credits)) {
    // If true, proceed to add the cart item in the cookie
    try {
      const cart = Cookies.get("cart");
      if (cart) {
        const cartItems = JSON.parse(cart);
        // JSON Validation
        const validate = ajv.compile(cartItemJsonSchema);
        const valid = validate(item);
        if (!valid) {
          throw new Error("Invalid cart item JSON" + JSON.stringify(item));
        }
        // Check if item with same project and vehicle_id exists, if so, add quantity to existing item
        const existingItem: ICartItem = cartItems.items.find(
          (i: ICartItem) =>
            i.project === item.project && i.vehicle_id === item.vehicle_id
        );
        if (existingItem) {
          existingItem.credits = (
            parseFloat(existingItem.credits) + parseFloat(item.credits)
          ).toString();
          if (
            await checkCreditAvailability(
              existingItem.project,
              existingItem.credits
            )
          )
            Cookies.set("cart", JSON.stringify(cartItems));
          else
            throw new Error(
              "Error : Your cart is over the maximum credit quantity available"
            );
          return cartItems;
        }
        cartItems.items.push(item);
        Cookies.set("cart", JSON.stringify(cartItems));
        return cartItems;
      } else {
        const cartItems = { items: [item] };
        Cookies.set("cart", JSON.stringify(cartItems));
        return cartItems;
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  } else {
    throw new Error("Error: Not enough credits available");
  }
};

function* addCartItemSaga(action: AddCartItemRequest) {
  try {
    const cart: AddCartItemSuccessPayload = yield call(() =>
      addCartItem(action.payload)
    );
    console.debug("addCartItemSaga", cart);
    yield put(addCartItemSuccess(cart));
  } catch (error: any) {
    yield put(addCartItemFailure({ error: error.message }));
  }
}

const deleteCart = async () => {
  try {
    Cookies.remove("cart");
    return { items: [] };
  } catch (error) {
    console.error(error);
    throw new Error("Error deleting cart");
  }
};

function* deleteCartSaga() {
  try {
    const cart: DeleteCartSuccessPayload = yield call(() => deleteCart());
    console.debug("deleteCartSaga", cart);
    yield put(deleteCartSuccess(cart));
  } catch (error) {
    yield put(deleteCartFailure({ error: "Error deleting cart." }));
  }
}

const removeCartItem = async (item: ICartItem) => {
  try {
    console.debug("removeCartItem", item);
    const cart = Cookies.get("cart");
    if (cart) {
      const cartItems = JSON.parse(cart);
      const newCartItems = cartItems.items.filter(
        (cartItem: ICartItem) =>
          JSON.stringify(cartItem) !== JSON.stringify(item)
      );
      console.debug("newCart", newCartItems);
      Cookies.set("cart", JSON.stringify({ items: newCartItems }));
      return { item: item };
    } else {
      throw new Error("Error removing cart item");
    }
  } catch (error) {
    console.error(error);
    throw new Error("Error removing cart item");
  }
};

function* removeCartItemSaga(action: RemoveCartItemRequest) {
  try {
    const deletedItem: RemoveCartItemSuccessPayload = yield call(() =>
      removeCartItem(action.payload)
    );
    yield put(removeCartItemSuccess(deletedItem));
  } catch (error) {
    yield put(removeCartItemFailure({ error: "Error removing cart item." }));
  }
}

const checkoutCartItem = async (item: ICartItem) => {
  console.debug("checkoutCartItem", item);
  try {
    const { data, error } = await supabase.rpc("create_credit_transaction", {
      vehicle_id: item.vehicle_id,
      project_id: item.project,
      credits_used: item.credits as unknown as number,
    });
    console.debug("checkoutCartItem", data, error);
    if (error) {
      //here we have to throw what we want to be stored in the error state of the reducer
      throw new Error(error.message + " " + error.hint);
    }
    return item;
  } catch (error) {
    throw new Error(error as string);
  }
};

function* checkoutCartItemSaga(action: CheckoutCartItemRequest) {
  try {
    const checkedOutCartItem: ICartItem = yield call(() =>
      checkoutCartItem(action.payload)
    );
    yield put(checkoutCartItemSuccess(checkedOutCartItem));
    const deletedItem: RemoveCartItemSuccessPayload = yield call(() =>
      removeCartItem(action.payload)
    );
    yield put(removeCartItemSuccess(deletedItem));
  } catch (error: any) {
    yield put(removeCartItemFailure({ error: error.message }));
  }
}

const checkoutCart = async (session: Session | null, checkoutType: CheckoutType) => {
  try {
    const cart = Cookies.get("cart");
    if (cart) {
      const cartItems = JSON.parse(cart);
      const { data, error } = await supabase.functions.invoke(
        `${checkoutType === "subscription" ? "stripe-subscription" : "stripe-payment-example"}`,
        {
          body: {
            items: cartItems.items,
            session: session,
          },
        }
      );
      if (error) {
        throw new Error(error.message);
      }
      if (data) {
        console.debug("checkoutCart", JSON.parse(data).clientSecret);
        return JSON.parse(data).clientSecret;
      }
    } else {
      throw new Error("No Items in Cart");
    }
  } catch (error) {
    console.error(error);
    throw new Error("Error checking out cart");
  }
};

function* checkoutCartSaga(action: CheckoutCartRequest) {
  try {
    const clientSecret: string = yield call(() =>
      checkoutCart(action.payload.session, action.payload.checkoutType)
    );
    yield put(checkoutCartSuccess(clientSecret));
  } catch (error: any) {
    yield put(checkoutCartFailure({ error: error.message }));
  }
}

function* setClientSecretSaga(action: SetClientSecretRequest) {
  try {
    yield put(setClientSecretSuccess(action.payload));
  } catch (error: any) {
    yield put(setClientSecretFailure({ error: error.message }));
  }
}
//this is basically the watcher for every vehicle requests
function* cartSaga() {
  yield all([takeLatest(GET_CART_REQUEST, getCartSaga)]);
  yield all([takeLatest(DELETE_CART_REQUEST, deleteCartSaga)]);
  yield all([takeLatest(REMOVE_CART_ITEM_REQUEST, removeCartItemSaga)]);
  yield all([takeLatest(ADD_CART_ITEM_REQUEST, addCartItemSaga)]);
  yield all([takeEvery(CHECKOUT_CART_ITEM_REQUEST, checkoutCartItemSaga)]);
  yield all([takeLatest(CHECKOUT_CART_REQUEST, checkoutCartSaga)]);
  yield all([takeLatest(SET_CLIENT_SECRET_REQUEST, setClientSecretSaga)]);
}

export default cartSaga;
