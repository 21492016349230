import { useAppDispatch, useAppSelector } from "app/hooks";
import { Nav } from "react-bootstrap";
import { BsSun, BsMoon } from "react-icons/bs";
import { setLightModeRequest } from "features/light_mode/actions";

function NightModeToggle() {
  const dispatch = useAppDispatch();
  const lightMode = useAppSelector((state) => state.light_mode.lightMode);

  return (
    <>
      <Nav.Link onClick={() => dispatch(setLightModeRequest(!lightMode))} className="text">
        {lightMode === true ? <BsSun /> : <BsMoon />}
      </Nav.Link>
    </>
  );
}

export default NightModeToggle;
