import {
    GET_MODELS_REQUEST,
    GET_MODELS_SUCCESS,
    GET_MODELS_FAILURE,
    } from './actionTypes';
    
    import {
        //all Brands
        GetModelsRequest,
        GetModelsSuccess,
        GetModelsSuccessPayload,
        GetModelsFailure,
        GetModelsFailurePayload,
        GetModelsRequestPayload,
    } from './types';
    
    
    // all projects
    export const getModelsRequest = (
        payload: GetModelsRequestPayload
    ): GetModelsRequest => ({
        type: GET_MODELS_REQUEST,
        payload,
    });
    
    export const getModelsSuccess = (payload: GetModelsSuccessPayload): GetModelsSuccess => ({
        type: GET_MODELS_SUCCESS,
        payload,
    });
    
    export const getModelsFailure = (payload: GetModelsFailurePayload): GetModelsFailure => ({
        type: GET_MODELS_FAILURE,
        payload,
    });