import { useParams } from "react-router-dom";
//import { supabase } from "app/supabaseClient";
import { useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getVehicleRequest } from "features/vehicles/actions";
import { StyledAccordion } from "app/theme";
import styled from "styled-components";

const StyledContainer = styled(Container)`
  max-height: 100vh;
  min-height: 100vh;
  background-image: linear-gradient(
      rgba(36, 39, 58, 0.8),
      rgba(36, 39, 58, 0.8)
    ),
    url("https://www.treehugger.com/thmb/nSp8ESJ1N6zq_bsTVL_MoSrKAqA=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-1273584292-cbcd5f85f4c646d58f7a7fa158dcaaeb.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: ${(props) => props.theme.colors.text};
  overflow-y: auto;
  overflow-x: hidden;
  & #treeSection {
    width: 60%;
    height: 20%;
    color: green;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
  }
  & .custom-accordion-button span {
    color: white !important;
  }
  & .custom-accordion-button::after {
    filter: brightness(1000);
  }
`;

interface supportedProjects {
  id: string;
  description: string;
  name: string;
  registry_web_page: string;
  background_image: string;
}

function Vehicle() {
  const dispatch = useAppDispatch();
  // Get the id from the url params
  const { id } = useParams();
  // Vehicle data state redux
  const vehicleData = useAppSelector((state) => state.vehicles.targetVehicle);
  console.log("vehicleData", vehicleData);
  // Mockup Data
  //const carbonCompensated = 4.5//vehicleData.total_credits;

  const getVehicle = async (id: string) => {
    dispatch(getVehicleRequest({ id: id }));
  };

  // Calculate the number of trees
  function calculateTrees() {
    let trees = "";
    // 1 ton of CO2 = 0.5 trees
    for (let i = 0; i < (vehicleData.total_credits * 1000) / 25; i++) {
      trees += "🌳";
    }
    return trees;
  }

  useEffect(() => {
    getVehicle(id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // You need to make sure the last join exists in the vehicle data or the component will fail to render
  return (
    <>
      <StyledContainer
        className="text-center"
        fluid
        style={{ padding: "100px 20px 20px 20px" }}
      >
        <>
          {vehicleData !== undefined ? (
            <>
              <div
                style={{
                  color: "white",
                }}
              >
                <h2>Vehicle Details</h2>
                <p>Numberplate: {vehicleData.number_plate}</p>
                <p>Model: {vehicleData.model_name}</p>
                <p>Brand: {vehicleData.brand_name}</p>
              </div>
              <div
                style={{
                  color: "white",
                }}
              >
                <h2>Carbon compensated</h2>
                <h3>{vehicleData.total_credits} tons of CO2</h3>
                <p>
                  This is equivalent to what a forest of{" "}
                  <span className="text-success">
                    {(vehicleData.total_credits * 1000) / 25} trees
                  </span>{" "}
                  can absorb in a year
                </p>
                <div id="treeSection">{calculateTrees()}</div>
              </div>

              <div className="mt-5">
                <h2
                  style={{
                    color: "white",
                  }}
                >
                  Supported Projects
                </h2>
                {vehicleData.total_credits !== 0 ? (
                  <StyledAccordion className="mt-4">
                    {vehicleData.supported_projects?.map(
                      (project: supportedProjects, index: number) => {
                        return (
                          <StyledAccordion.Item
                            eventKey={index.toString()}
                            key={`accordion-item-${index}`}
                            style={{
                              backgroundImage: `linear-gradient(rgba(36, 39, 58, 0.7), rgba(36, 39, 58, 0.7)), url(${project.background_image})`,
                              backgroundSize: "cover",
                            }}
                          >
                            {/* <Accordion.Header></Accordion.Header> */}
                            <StyledAccordion.Button
                              className="custom-accordion-button"
                              style={{
                                backgroundColor: "transparent",
                                boxShadow: "none",
                              }}
                            >
                              <span>{project.name}</span>
                            </StyledAccordion.Button>
                            <StyledAccordion.Body
                              className="text-justify"
                              style={{
                                color: "white",
                              }}
                            >
                              <p>{project.description}</p>
                              <a
                                href={project.registry_web_page}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Click here to see the project
                              </a>
                            </StyledAccordion.Body>
                          </StyledAccordion.Item>
                        );
                      }
                    )}
                  </StyledAccordion>
                ) : (
                  <p>This vehicle has not compensated any carbon yet</p>
                )}
              </div>
            </>
          ) : (
            <>
              <Spinner animation="grow" />
              Loading...
            </>
          )}
        </>
      </StyledContainer>
    </>
  );
}

export default Vehicle;
