import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import "./assets/scss/bootstrap-override.scss";
import PageNotFound from "./pages/NotFound/PageNotFound";
import { RouterPath } from "./app/router-path";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/Home/Home";
import NavBar from "./components/layout/NavBar";
import AdminDashboard from "./pages/Admin/Admin";
import Vehicle from "./pages/Vehicle/Vehicle";
import { useEffect } from "react";
import { supabase } from "./app/supabase-client";
// import { getSession, setSession } from "./actions/session";
import { useAppDispatch, useAppSelector } from "./app/hooks";
// import { passwordRecoveryUpdated } from "./features/auth/session/session-slice";
import Auth from "./pages/Auth";
import { GlobalStyle, darkTheme, lightTheme } from "./app/theme";
import { ThemeProvider } from "styled-components";
import Alert from "components/CustomAlerts";
import SuccessAlert from "components/CustomAlerts/SuccessAlert";
import Vehicles from "pages/Dashboard/Vehicles";
import SupportedProjectsList from "pages/Dashboard/SupportedProjects";
import Cart from "pages/Cart";
import { getCartRequest } from "features/cart/actions";
import { getLightModeRequest } from "features/light_mode/actions";
import Profile from "pages/Profile";
import { getUserRequest } from "features/auth/user/actions";
import {
  getSessionRequest,
  passwordRecoveryRequest,
  setSessionRequest,
} from "features/auth/session/actions";
import { Session } from "@supabase/gotrue-js";
import Checkout from "pages/Checkout";
import { PasswordRecoveryPage } from "pages/Auth/ResetPassword";
import CheckoutSubscription from "pages/Subscription";

function App() {
  const dispatch = useAppDispatch();
  const session = useAppSelector((state) => state.session.session);
  const userRole = useAppSelector((state) => state.user.user.role);
  const lightMode = useAppSelector((state) => state.light_mode.lightMode);
  const passwordRecovery = useAppSelector(
    (state) => state.session.passwordRecovery
  );

  useEffect(() => {
    // Connect to Supabase
    dispatch(getSessionRequest() as any);
    // Listen for changes to the session
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        // Update the session in the store
        dispatch(setSessionRequest(session as Session) as any);
        // If the user has requested a password recovery, update the store to handle the UI
        if (_event === "PASSWORD_RECOVERY") {
          dispatch(passwordRecoveryRequest());
        }
      }
    );
    // Get the cart
    dispatch(getCartRequest());

    // Get the light mode
    dispatch(getLightModeRequest());

    // Cleanup listener on unmount
    return () => {
      authListener.subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (session) dispatch(getUserRequest(session));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useEffect(() => {
    console.debug("Light mode changed to: ", lightMode);
  }, [lightMode]);

  return (
    <ThemeProvider theme={lightMode ? lightTheme : darkTheme}>
      <div className="App">
        <GlobalStyle />
        <Alert></Alert>
        <SuccessAlert></SuccessAlert>
        <BrowserRouter>
          <NavBar></NavBar>
          <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route path={RouterPath.home} element={<Home />} />
            {session && userRole === "admin" ? (
              <Route path={RouterPath.admin} element={<AdminDashboard />} />
            ) : null}
            {session && userRole !== "admin" ? (
              <>
                <Route
                  path={`${RouterPath.dashboard}`}
                  element={<Vehicles />}
                />
                <Route path={RouterPath.myVehicles} element={<Vehicles />} />
                <Route
                  path={RouterPath.mySupportedProjects}
                  element={<SupportedProjectsList />}
                />
                <Route path={RouterPath.cart} element={<Cart />} />
                <Route path={RouterPath.checkout} element={<Checkout />} />
                <Route path={RouterPath.checkoutSubscription} element={<CheckoutSubscription />} />
                <Route path={RouterPath.profile} element={<Profile />} />
              </>
            ) : null}
            {passwordRecovery ? (
              <Route
                path={RouterPath.passwordRecovery}
                element={<PasswordRecoveryPage />}
              />
            ) : null}
            <Route path={`${RouterPath.vehicle}:id`} element={<Vehicle />} />
            <Route path={`${RouterPath.login}`} element={<Auth />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
