import {
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_SUCCESS,
    GET_PROJECTS_FAILURE,
    GET_SUPPORTED_PROJECTS_REQUEST,
    GET_SUPPORTED_PROJECTS_SUCCESS,
    GET_SUPPORTED_PROJECTS_FAILURE,
} from "./actionTypes";

import {
    //all projects
    GetProjectsRequest,
    GetProjectsSuccess,
    GetProjectsSuccessPayload,
    GetProjectsFailure,
    GetProjectsFailurePayload,

    //supported projects
    GetSupportedProjectsRequest,
    GetSupportedProjectsSuccess,
    GetSupportedProjectsSuccessPayload,
    GetSupportedProjectsFailure,
    GetSupportedProjectsFailurePayload,
} from "./types"

// all projects
export const getProjectsRequest = (): GetProjectsRequest => ({
    type: GET_PROJECTS_REQUEST,
});

export const getProjectsSuccess = (payload: GetProjectsSuccessPayload): GetProjectsSuccess => ({
    type: GET_PROJECTS_SUCCESS,
    payload,
});

export const getProjectsFailure = (payload: GetProjectsFailurePayload): GetProjectsFailure => ({
    type: GET_PROJECTS_FAILURE,
    payload,
});

// supported projects
export const getSupportedProjectsRequest = (): GetSupportedProjectsRequest => ({
    type: GET_SUPPORTED_PROJECTS_REQUEST,
});

export const getSupportedProjectsSuccess = (payload: GetSupportedProjectsSuccessPayload): GetSupportedProjectsSuccess => ({
    type: GET_SUPPORTED_PROJECTS_SUCCESS,
    payload,
});

export const getSupportedProjectsFailure = (payload: GetSupportedProjectsFailurePayload): GetSupportedProjectsFailure => ({
    type: GET_SUPPORTED_PROJECTS_FAILURE,
    payload,
});